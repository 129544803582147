import {Task, TaskMember, TaskMemberType} from "../../generated-types";
import React, { useEffect, useRef, useState } from "react";
import {Avatar, ConfigProvider, Popover, theme} from "antd";
import UserAvatar from "../User/UserAvatar";
import styled, { css } from "styled-components";
import Dropdowned from "../Dropdowned";


interface TaskMembersProps {
    task: Task
}


const TaskMembersContainer=styled.div`

    display: flex;
    gap: 4px;
`
const AvatarGroupStyled=styled(Avatar.Group)`
    
    display: flex;
    gap: 4px;

`

const MaxAvatar=styled(Avatar)`
    
    background-color: unset;
    color: ${({theme})=>theme.colors.font.primary};
    border: ${({theme})=>`${theme.colors.ui.bgLight} 1px solid`} !important;
    user-select: none;
    width: 24px;
    height: 24px;
    font-size: 10px !important;
`

const OverrideContent=styled.div`

display: flex;
flex-direction: column;
gap: 4px;
padding: 8px;
border-radius: 16px;

&>div{
    display: flex;
    gap: 4px;
    align-items: center;

    >span.user-name{
        font-size: 12px;
        font-weight: 400;
    }
}
`

const TaskMembers: React.FC<TaskMembersProps> = ({task}) => {

    const {token}=theme.useToken();
    const maxCount=2
    const [showOverride, setShowOverride]=useState(false)
    const showOverrideBtnRef=useRef<HTMLSpanElement>(null)

    const responsible = task.members?.filter(m => m.memberType == TaskMemberType.Responsible)

    const members = task.members?.filter(m => m.memberType != TaskMemberType.Responsible)

    const allTaskMembers=responsible.concat(members);

    const getAvatarClassName=(member: TaskMember)=>{
        return member.memberType===TaskMemberType.Responsible? 'responsibleAvatar' : ''
    }
    return <TaskMembersContainer>
                <AvatarGroupStyled
                    size="default"
                    max={{count: maxCount}}
                >
                    {allTaskMembers.slice(0,maxCount).map(member=><UserAvatar className={getAvatarClassName(member)} key={member.user.id} user={member.user}/>)}
                </AvatarGroupStyled>
                {allTaskMembers.length>maxCount && <>
            
                        <MaxAvatar onClick={(e)=>{
                            e?.preventDefault()
                            e?.stopPropagation()
                            setShowOverride(true)}} ref={showOverrideBtnRef}>
                            +{allTaskMembers.length-maxCount}
                        </MaxAvatar>
                        {showOverride && <Dropdowned popupOnly onClose={()=>setShowOverride(false)} opened={showOverride} anchor={showOverrideBtnRef}>
                        <OverrideContent>
                            {allTaskMembers.slice(maxCount).map(member=><div>
                            <UserAvatar className={getAvatarClassName(member)} key={member.user.id} user={member.user}/>
                            <span className="user-name">{member.user.fullName || member.user.username}</span>
                            </div>
                            )}
                            </OverrideContent>
                        </Dropdowned>}
                        </>
                }
            </TaskMembersContainer>

}

export default TaskMembers