import { message, Row, Table, theme } from 'antd';
import React from 'react';
import { Project, Task, TasksView, TaskViewFilterProject, useAddTaskMembersMutation, useTaskAttachTagsMutation } from "../../generated-types";
import Tagger from "../Tagger/Tagger";
import TaskChangeColumn, { taskChangeColumnView } from "./TaskChangeColumn";
import dayjs from 'dayjs';
import TaskMembers from "./TaskMembers";
import TaskResolved from "./TaskResolved";
import TaskTitle from "./TaskTitle";
import styled, { css } from 'styled-components';
import TaskNumber from './TaskNumber';
import TaskImportance, { allPriorities, TaskImportanceElement } from './TaskImportance';
import AddTaskInput from './AddTaskInput';
import PointDivider from '../PointDivider';
import TaskFilesAndChildTasks from './TaskFilesAndChildTasks';
import { useTranslation } from 'react-i18next';
import TaskMembersEditor from './TaskMembersEditor';
import { useReactiveVar } from '@apollo/client';
import { allProjects } from '../../subscriptions/allProjects';
import { all } from 'axios';
import RefetchesList from '../../queries/RefetchesList';

const dateFormat = 'D MMMM';
const timeFormat ='HH:MM';

const columnsWidths = [20, 237, 325, 165, 124, 162, 170, 62]

const TaskListContainer = styled.div`
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    padding-top: 24px;
`

const paddingLeftRight = css`
    padding-left: ${p => p.theme.isMobile ? '16px' : '40px'};
    padding-right: ${p => p.theme.isMobile ? '16px' : '40px'};
`

const TableScrollContainer = styled.div`
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
`

const TableScrollContent = styled.div`
${paddingLeftRight}
height: 100%;
width: 100%;
overflow-y: hidden
`

const TasksListContainer = styled.div`
    background-color: ${p => p.theme.colors.ui.bgLight2};
    height: 100%;
    width: 100%;
    min-width: 1539px;
    border-radius: 8px;
    overflow-y: auto;

`
const TableStyled = styled(Table)`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .ant-table-thead{
    
        .ant-table-cell{
            font-weight: 400;
            font-size: ${p=>p.theme.isMobile? '12px' : '14px'};

            &::before{
                display: none;
            }
        }
    }

    
    .ant-table-cell{
        padding: 8px 16px !important;
        background-color: ${p=>p.theme.colors.ui.bgLight2};
        transition: all .3s ease-in-out;

        &.number-column{
            width: 50px;
        }

        &.status-column{
            width: 248px;
        }
    }
    
    .ant-table-cell-row-hover{

        background-color: ${p=>p.theme.colors.ui.bgLight3} !important;
    }

    .ant-table-row:last-child{
        .ant-table-cell{
            border: none;
        }
    }
    
    .ant-table-footer{
        height: 56px;
    }
`

const TaskInputcontainer=styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: ${p=>p.theme.isMobile? '16px 32px' : '16px 40px'};
    z-index: 2;
    width: 100%;
    
    >.ant-input-affix-wrapper{
        width: ${p=>p.theme.isMobile? '100%' : '420px'};
    }
    &::before{ 
        content: '';
        position: absolute;    
        bottom: 0;
        left: 0;
        height: 60px;
        width: 100%;
        background: linear-gradient(to top, ${p => p.theme.colors.ui.bgLight}, rgba(255, 255, 255, 0));
        opacity: 1;
    }

`
const TasksList: React.FC<{ tasks: Task[], taskView: TasksView, showProjectTitle?: boolean }> = ({ tasks, taskView, showProjectTitle }) => {

    const { token } = theme.useToken();
    const {t}=useTranslation();
    const {projects}=useReactiveVar(allProjects)
    const [attachTags]=useTaskAttachTagsMutation({
        onError: (error, clientOptions)=>{
                
                message.error(t('tags.tagDelitionErrorMessage'))
        },
        refetchQueries: [RefetchesList.TasksByViewId]
    })
    
    const columns = [
        {
            title: '',
            render: (task: Task) => <TaskNumber task={task} />,
            key: "number",
            className: 'number-column',
            width: 0
        },
        {
            title: t('task.taskslistColumnsTitles.status'),
            render: (task: Task) => <TaskChangeColumn taskView={taskView} task={task} allowEdit={!task.resolved} view={taskChangeColumnView.toggle}/>,
            key: "status",
            className: 'status-column',
            width: 0
        },
        {
            title: t('task.taskslistColumnsTitles.title'),
            render: (task: Task) => <Row style={{ gap: 8, flexWrap: 'nowrap', alignItems: 'center' }}>
                <TaskResolved
                    id={task.id}
                    disabled={task.project.archived || task.project.paymentAccount?.isBlocked}
                    value={task.resolved}
                    key={"" + task.resolved}
                />
                <TaskTitle task={task} tasksView={taskView}/>
            </Row>,
            key: "title",
            className: 'title-column',
            width: 0
        },
        {
            title: t('task.taskslistColumnsTitles.priority'),
            render: (task: Task) => <TaskImportance value={task.importance?? 0} id={task.id}/>,
            key: "priority",
            className: 'priority-column',
            width: 0
        },
        {
            title: t('task.taskslistColumnsTitles.members'),
            render: (task: Task) => {

                const taskProject=projects.find(p=>task.project.id==p.id)?? {...task.project, members: []} as Project
                
                return <TaskMembersEditor avatarsOnly maxToShow={2} task={task} project={taskProject} sendOnChange 
                onUpdateMembers={() => {}}/>},
            key: "members",
            className: 'members-column',
            width: 0
        },
        {
            title: t('task.taskslistColumnsTitles.deadline'),
            key: "deadline",
            className: 'deadline-column',
            render: (task: Task)=><>
            {task.endDate && <span>до {dayjs(task.endDate).format(dateFormat)}</span>}
            {task.endTime && <PointDivider valueOpacity={1} value={dayjs(task.endTime).format(timeFormat)} />}
            </>,
            width: 0
        },
        {
            title: t('task.taskslistColumnsTitles.tags'),
            render: (task: Task) => (task.tags && <Tagger projectId={task.project.id} defaultValue={task.tags} maxTags={5} maxLines={2} onChanged={(tags)=>{
                const tagsTitles=tags.map(tag=>tag.title)
                attachTags({variables: {
                    taskId: task.id,
                    tags: tagsTitles
                
                }
                })
            }}/>),
            key: "tags",
            className: 'tags-column',
            width: 0
        },
        {
            title: '',
            key: "icons",
            className: 'icons-column',
            render: (task: Task)=><TaskFilesAndChildTasks task={task}/>,
            width: 0
        }
    ]

    columns.forEach((col, i)=>col.width=columnsWidths[i]+32)
    
    return (
        <TaskListContainer>
            <TableScrollContainer>
                <TableScrollContent>
                    <TasksListContainer>
                        <TableStyled
                        rowKey={'id'}
                        style={{height: '100%'}} 
                        sticky columns={columns} 
                        dataSource={tasks} 
                        pagination={false} 
                        //scroll={{y: "calc(100% - 55px)"}}
                        tableLayout='fixed'
                        footer={() => ''}
                        />
                    </TasksListContainer>
                </TableScrollContent>
                <TaskInputcontainer>
                    <AddTaskInput
                        fastAddOnly={false}
                        showPrefix
                        isBlocked={taskView.project?.paymentAccount?.isBlocked}
                        ctx={{
                        projectId: (taskView.filterValue as TaskViewFilterProject)?.projectId,
                        tasksViewColumnId: taskView.columns[0].id,
                        taskViewId: taskView.id,
                        status: taskView.columns[0].title,
                        taskId: undefined,
                        taskView: taskView
                    }} />
            </TaskInputcontainer>
            </TableScrollContainer>
        </TaskListContainer>
    )
};

export default TasksList;
