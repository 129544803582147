import React from 'react'
import styled from 'styled-components'
import { copyTaskLink } from './TaskLinkButton'
import { Task } from '../../generated-types'


const TaskNumberContainer = styled.div`
    border-radius: 4px;
    padding: 6px;
    background-color: ${({ theme }) => theme.colors.ui.bgLight};
    font-size: 12px;
    line-height: 1;
    color: ${({ theme }) => theme.colors.font.primarySemitransparent};
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    width: fit-content;
    cursor: pointer;
    
    &:hover{
      background-color: ${({ theme }) => theme.colors.ui.bgLight2};
    }
`
function TaskNumber({task}: {task: Task}) {

  const clickHandler=(e: any)=>{
    e.preventDefault();
    e.stopPropagation();
    copyTaskLink(task.id, task.project.id)
  }
  return (
    <TaskNumberContainer onClick={clickHandler}>#{task.number}</TaskNumberContainer>
  )
}

export default TaskNumber