import {User} from "../../generated-types";
import React from "react";
import {theme} from "antd";
import {useTranslation} from "react-i18next";
import {Icon} from "../Icon/Icon";
import {IconMainWorker} from "../Icon/IconMainWorker";
import {IconWorker} from "../Icon/IconWorker";
import UserAvatar from "../User/UserAvatar";
import TooltipButton from "../TooltipButton";
import {IconClose} from "../Icon/IconClose";
import styled, {css} from "styled-components";
import {GlobalToken} from "antd/es/theme/interface";


const TaskMemberItem = styled.div<{ $fullwidth: string, $clickable: string, $avatarOnly: boolean}>`

    ${p=>!p.$avatarOnly && css`
        background-color: ${p.theme.colors.ui.bgLight};
        border-radius: 8px;
        height: 32px;
        padding: 2px 12px;
        display: flex;
        align-items: center;
        float: left;
        box-sizing: border-box;
        
        &:hover {
            background-color: ${p.theme.colors.ui.bgLight3};
        }
    `}
    

    ${({$clickable}) => $clickable === "true" && css`
        cursor: pointer;
    `}
    ${({$fullwidth}) => $fullwidth === "true" && css`
        width: 100%;
    `}
   

    .member-avatar {
        width: 24px;
        height: 24px;
    }

    .ant-btn {
        width: 16px;
        height: 16px;
        justify-content: center;
        align-items: center;
        
        >.ant-btn-icon{
            height: inherit;
            width: inherit;
        }
    }

    .user-title {
        padding: 0 8px;
        font-weight: 300;
        ${({$fullwidth}) => $fullwidth === "true" && css`
            flex-grow: 1;
        `}
        line-height: 1.2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        align-content: space-around;
        
    }

    .user-avatar-container{
        flex-grow: 1;
    }

    .member-avatar {
        cursor: pointer;
    }

    .member-avatar.responsible {
        border: 2px solid ${p => p.theme.colors.ui.accent};
    }

    .member-avatar:hover {
        border: 2px solid ${p => p.theme.colors.ui.accent};
    }

    .resp-icon {
        margin-right: 5px;
    }

    .resp-icon:hover {
        cursor: pointer;
        fill: ${p => p.theme.colors.ui.accent}
    }
`

interface TaskMemberIProps {
    user: User,
    flash: boolean,
    responsible: boolean,
    removable?: boolean
    fullwidth?: boolean
    onClick?: () => void
    onSetResponsible: (v: boolean) => void
    onRemove?: () => void,
    avatarOnly?: boolean,
}

const TaskMemberI: React.FC<TaskMemberIProps> = ({
                                                     user,
                                                     flash,
                                                     responsible,
                                                     removable = true,
                                                     fullwidth = false,
                                                     onSetResponsible,
                                                     onRemove,
                                                     onClick,
                                                     avatarOnly=false,
                                                 }) => {
    const {token} = theme.useToken();
    const {t} = useTranslation()

    const cname = (!flash ? "member-avatar" : "") + (responsible ? " responsible" : "")+' user-avatar';

    return <TaskMemberItem  $avatarOnly={avatarOnly} 
                            $fullwidth={fullwidth.toString()} 
                            className={"task-member-item"}
                            $clickable={(onClick != undefined).toString()}
                            onClick={e => {
                               if (onClick) onClick();
                               e.stopPropagation();
                               e.preventDefault();
                           }}>
        {flash && <Icon size={"16"} color={token.colors.ui.accent}
				                icon={responsible
                                    ? <IconMainWorker className={"resp-icon"} onClick={(e) => {
                                        onSetResponsible(!responsible)
                                        e.stopPropagation();
                                        e.preventDefault();

                                    }}/>
                                    : <IconWorker className={"resp-icon"} onClick={(e) => {
                                        onSetResponsible(!responsible)
                                        e.stopPropagation();
                                        e.preventDefault();

                                    }}/>
                        }/>
        }
        <UserAvatar user={user} className={cname} addTitle={!avatarOnly} onClickAvatar={() => {onSetResponsible(!responsible)}}/>
        {removable && !avatarOnly && <TooltipButton
					icon={<Icon size={"16"} color={token.colors.ui.bgDark} icon={<IconClose/>}/>}
					type={"text"}
					onClick={onRemove}
					tooltipProps={{title: t('task.membersList.removeMember')}}>
				</TooltipButton>}
    </TaskMemberItem>
}

export default TaskMemberI;