import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Avatar, Input, theme, } from 'antd';
import { Project, ProjectMember, Task, TaskMember, TaskMemberType, useAddTaskMembersMutation, User, UsersUnionMember } from "../../generated-types";
import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";
import { allUsersUnions } from "../../subscriptions/allUsersUnions";
import Dropdowned from "../Dropdowned";
import { Icon } from "../Icon/Icon";
import TooltipButton from "../TooltipButton";
import { IconPlus } from "../Icon/IconPlus";
import styled from "styled-components";
import type { GlobalToken } from "antd/es/theme/interface";
import TaskMemberI from "./TaskMemberI";
import TaskMembers from './TaskMembers';
import UserAvatar from '../User/UserAvatar';
import RefetchesList from '../../queries/RefetchesList';

interface Member {
    user: User,
    tMember?: TaskMember,
    projectMember: ProjectMember | undefined,
    uuMember: UsersUnionMember,
    selected: boolean,
    selectedType: TaskMemberType
}


interface TaskMembersEditorProps {
    task: Task,
    project: Project,
    onUpdateMembers: (members: TaskMember[]) => void,
    avatarsOnly?: boolean,
    maxToShow?: number,
    sendOnChange?: boolean
}

const TaskMembersEditorDropdownStyled = styled.div<{ $token: GlobalToken }>`
    & {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    & > .inner-container {
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        background-color: ${({ $token }) => $token.colors.ui.bgLight};

        .task-member-item {
            margin-bottom: 0;
            border-radius: 0;
        }

        .task-member-item:last-child {
            border-radius: 0 0 16px 16px;
        }
    }

    .inner-container-header {
        padding: 12px 12px 0px;
    }
`

export const Search = styled(Input) <{ $token: GlobalToken }>`
    margin: 8px 12px;
    width: 90%;
    border-radius: 8px;
    background-color: ${(p) => p.$token.colors.ui.bgLight2};

    color: ${(p) => p.$token.colors.font.primary};
`


export const TaskMembersEditorDropdown: React.FC<{
    selectedMembers: Member[],
    projectMembers: Member[],
    onAdd: (member: Member, responsible: boolean) => boolean
    onSetResponsible: (member: Member, responsible: boolean) => boolean
    onRemove: (member: Member) => boolean
}> = ({ selectedMembers, projectMembers, onSetResponsible, onRemove, onAdd }) => {
    const { t } = useTranslation()
    const { token } = theme.useToken();
    const [searchValue, setSearchValue] = useState<string | null>(null);

    const filterFunc = (value: Member) => {
        if (!searchValue) return true;
        const v = value.user.fullName + "_" + value.user.username
        return v.toLowerCase().includes(searchValue.toLowerCase());
    };

    let pp = projectMembers.filter(v => !v.selected);

    return (
        <TaskMembersEditorDropdownStyled $token={token}>
            {selectedMembers.length > 0 && <div className={"inner-container"}>
                <div className={"inner-container-header"}>{t('task.membersList.label')}</div>
                {selectedMembers.map(m => {
                    return <TaskMemberI key={m.user.id} user={m.user} flash={true} fullwidth={true}
                        onSetResponsible={(v: boolean) => {
                            onSetResponsible(m, v)
                        }}
                        onRemove={() => {
                            onRemove(m)
                        }}
                        responsible={m.selectedType == TaskMemberType.Responsible} />;
                })}
            </div>}

            {pp.length > 0 && <div className={"inner-container"}>
                <div className={"inner-container-header"}>{t('task.membersList.addProjectMember')}</div>
                <Search $token={token} placeholder={t('task.membersList.search')}
                    onChange={(e) => {
                        setSearchValue(e.target.value)
                    }}
                />
                {pp.filter(filterFunc).map(m => {
                    return <TaskMemberI user={m.user} flash={true} fullwidth={true} key={m.user.id}
                        onClick={() => {
                            onAdd(m, false)
                        }}
                        onSetResponsible={(v: boolean) => {
                            onAdd(m, v)
                        }}
                        removable={false} responsible={false} />;
                })}
            </div>}
        </TaskMembersEditorDropdownStyled>
    )
}

const OverrideContent = styled.div`

    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;
    border-radius: 16px;

    &>div{
        display: flex;
        gap: 4px;
        align-items: center;

        >span.user-name{
            font-size: 12px;
            font-weight: 400;
        }
    }
    `


const TaskMemberOverrideDropdowned = ({ members, onSwitchResponsible }: { members: Member[], onSwitchResponsible: (member: Member)=>void }) => {

    return <OverrideContent>
        {members.map(member => <div>
        <TaskMemberI 
        user={member.user} 
        responsible={member.selectedType===TaskMemberType.Responsible} 
        flash={false}
        onSetResponsible={()=>onSwitchResponsible(member)}
        avatarOnly
        />
        <span className="user-name">{member.user.fullName}</span>
        </div>)
        
        }
    </OverrideContent>
}
const TaskMemberHolder = styled.div<{ $avatarsOnly: boolean }>`
    display: inline-flex;
    align-items: center;
    gap: ${p => p.$avatarsOnly ? '4px' : '8px'};
    flex-wrap: wrap;

    >.add-button{
        width: 24px;
        height: 24px;
        padding: 0;
        
        >.ant-btn-icon{
            height: inherit;
            width: inherit;
            
        }
    }
`

const MaxAvatar=styled(Avatar)`
    
    background-color: unset;
    color: ${({theme})=>theme.colors.font.primary};
    border: ${({theme})=>`${theme.colors.ui.bgLight} 1px solid`} !important;
    user-select: none;
    width: 24px;
    height: 24px;
    font-size: 10px !important;
`

const TaskMembersEditor: React.FC<TaskMembersEditorProps> = ({ task, project, onUpdateMembers, avatarsOnly = false, maxToShow, sendOnChange=false }) => {
    const { t } = useTranslation()
    const allUnions = useReactiveVar(allUsersUnions);
    const { token } = theme.useToken();
    const isChanged=useRef<boolean>(false)
    const taskMemberHolderRef = useRef<HTMLDivElement>(null)
    const [addMembers]=useAddTaskMembersMutation(
            {
                refetchQueries: [RefetchesList.TasksByViewId]
            }
        )

    let mmbs = useMemo(() => {
        let res = project.members.map(m => {
            const tm = task.members?.find(tm => tm.user.id == m.user.id);
            return {
                user: m.user,
                projectMember: m,
                tMember: tm,
                selected: tm != undefined,
                selectedType: tm?.memberType
            }
        }) as Member[]

        if (project.usersUnionAsOwner) {
            const uu = allUnions.unions.find(v => v.id === project?.usersUnionAsOwner?.id);
            const uuMembersNotInProject = uu?.members.filter(m => !res.some(mm => mm.user.id === m.user.id));
            if (uuMembersNotInProject)
                res = res
                    .concat(uuMembersNotInProject.map(uum => {
                        const tm = task.members?.find(tm => tm.user.id == uum.user.id);
                        return {
                            uuMember: uum,
                            user: uum.user,
                            projectMember: undefined,
                            tMember: tm,
                            selected: tm != undefined,
                            selectedType: tm?.memberType
                        } as Member
                    }))
        }
        return res;
    }, [project.members, project.usersUnionAsOwner, task.members])

    // Список участников, которые отображаются в списке
    const [members, setMembers] = useState<Member[]>(mmbs);
    const [opened, setOpened] = useState(false);

    const [showOverride, setShowOverride] = useState(false)
    const showOverrideBtnRef = useRef<HTMLSpanElement>(null)

    useEffect(() => {

        const taskMembers = members.filter(m => m.selected)
            .map(m => {
                let tm = m.tMember ? { ...m.tMember } : { user: m.user } as TaskMember;
                tm.memberType = m.selectedType ?? m.tMember?.memberType;
                return tm;
            })

        sendOnChange && isChanged.current && addMembers({
                variables:{
                    taskId: task.id,
                    members: taskMembers.map(member=>({userId: member.user.id, memberType: member.memberType}))
                },
                onCompleted: ()=>{
                    isChanged.current=false
                }
            })

        onUpdateMembers([...taskMembers])
        
    }, [members]);

    const switchMember = (m: Member, responsible: boolean = false) => {
        m.selected = !m.selected;
        m.selectedType = TaskMemberType.Member;
        isChanged.current=true
        if (responsible)
            switchResponsible(m)
        else
            setMembers([...members.filter(v => v.user.id != m.user.id), m])
    }

    const switchResponsible = (m: Member) => {
        const currentValue = m.selectedType;
        members.forEach(m => { m.selectedType = TaskMemberType.Member })
        m.selectedType = currentValue == TaskMemberType.Responsible ? TaskMemberType.Member : TaskMemberType.Responsible;
        isChanged.current=true
        setMembers([...members.filter(v => v.user.id != m.user.id), m])
    }

    const selectedMembers = useMemo(() => {
        return members.filter(m => m.selected).sort((m1, m2) => {
            if (m1.selectedType == TaskMemberType.Responsible) return -1;
            if (m2.selectedType == TaskMemberType.Responsible) return 1;
            return (m1.user.fullName ?? m1.user.username ?? "") > (m2.user.fullName ?? m2.user.username ?? "") ? 1 : -1;
        })
    }, [members]);

    const showedMembers=maxToShow? selectedMembers.slice(0, maxToShow) : selectedMembers
    
    return (<>
        <TaskMemberHolder $avatarsOnly={avatarsOnly} ref={taskMemberHolderRef}>
            {showedMembers.map(m => <TaskMemberI key={m.user.id}
                onSetResponsible={() => {
                    switchResponsible(m)
                }}
                onRemove={() => {
                    switchMember(m)
                }}
                user={m.user} flash={false}
                responsible={m.selectedType == TaskMemberType.Responsible}
                avatarOnly={avatarsOnly}
            />)}
            {maxToShow && selectedMembers.length > maxToShow && <>

                <MaxAvatar onClick={(e) => {
                    e?.preventDefault()
                    e?.stopPropagation()
                    setShowOverride(true)
                }} ref={showOverrideBtnRef}>
                    +{selectedMembers.length - maxToShow}
                </MaxAvatar>
                {showOverride && <Dropdowned popupOnly onClose={() => setShowOverride(false)} opened={showOverride} anchor={showOverrideBtnRef}>
                    <TaskMemberOverrideDropdowned members={selectedMembers.slice(maxToShow)} onSwitchResponsible={(m)=>switchResponsible(m)}/>
                </Dropdowned>}
            </>
            }

            <TooltipButton className='add-button'
                icon={<Icon size={"24"} color={token.colors.font.accent} icon={<IconPlus />} />}
                type={"text"}
                onClick={() => {
                    setOpened(true);
                }}
                tooltipProps={{ title: t('task.membersList.addMembers') }}>
            </TooltipButton>
        </TaskMemberHolder>

        {opened &&
            <Dropdowned opened={opened} anchor={taskMemberHolderRef} onClose={o => {
                setOpened(o)
            }} title={t('task.membersList.choiceMember')}>
                <TaskMembersEditorDropdown
                    onAdd={(m: Member, responsible: boolean) => {
                        switchMember(m, responsible)
                        return true;
                    }}
                    onSetResponsible={(member: Member, responsible: boolean) => {
                        switchResponsible(member)
                        return true;
                    }}
                    onRemove={(m: Member) => {
                        switchMember(m)
                        return true;
                    }}
                    selectedMembers={selectedMembers}
                    projectMembers={mmbs}
                />
            </Dropdowned>}

    </>
    );
};

export default TaskMembersEditor