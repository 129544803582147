import React from 'react';
import {Button, Col, Divider, message, Row, Tooltip} from 'antd';
import {PoweroffOutlined} from "@ant-design/icons";
import {useReactiveVar} from "@apollo/client";
import {authState, clearToken} from "../../routes/Auth/authContext";
import {User, UserProfileInput, useUserProfileQuery, useUserProfileUpdateMutation} from "../../generated-types";
import AvatarEditor from "./AvatarEditor";
import {useNavigate} from 'react-router-dom';
import ProfileInfo from './ProfileInfo';
import {useTranslation} from "react-i18next";
import {PaymentAccount} from "../Payment/PaymentAccount";
import {PaymentAccountHistory} from "../Payment/PaymentAccountHistory";


const Profile: React.FC = () => {
    const {t} = useTranslation();
    const { user: {id} } = useReactiveVar(authState);
    const navigate = useNavigate();
    const [userProfileUpdate, {error: errorAdd }] = useUserProfileUpdateMutation();
    const [messageApi, contextHolder] = message.useMessage();
    const updateUserDate = (input:UserProfileInput) => {
        userProfileUpdate({
            variables: {input},
            onError: (error) => {
                message.error(error.message, 4)
            },
            onCompleted:()=>success()
        });
    };

    const success = () => {
        messageApi.open({
            type: 'success',
            content: t('editDataMassage'),
            style: {
                marginTop: '20vh',
            },
        });
    };

    const {data} = useUserProfileQuery();
    if (!data) {
        navigate('/signIn')
        return <></>
    }
    const user = data.userProfile as User;
    return (
        <section style={{padding: "30px"}}>
            <Row style={{marginLeft:45}}>
                <Col flex={"auto"}><h3 test-id="profile-title">{user.username}</h3></Col>
                <Col flex={"40px"}>
                    <Tooltip title={t('auth.signOut')}>
                        <Button icon={<PoweroffOutlined rev={undefined} />} type={"default"}
                                onClick={()=>clearToken().then(()=>window.location.reload())}
                        />
                    </Tooltip>
                </Col>
            </Row>
            <Divider />
            <Row gutter={20} justify={"start"} >
                <Col span={12} style={{display:"flex",flexDirection:'column',gap:24}} >
                <div className="avatar-editor-section">
                    <p>{t('По фотографии коллеги по группе смогут легко узнать вас')}</p>

                    <AvatarEditor contextType={user.__typename} contextId={user.id} avatarUrl={user.avatarUrl}/>
                </div>
                    <ProfileInfo userData={user} error={errorAdd} onSendHandler={updateUserDate}/>
                    {contextHolder}
                <Divider />
                </Col>
                <Col span={12} style={{display:"flex",flexDirection:'column',gap:24}}>
                    <PaymentAccount/>
                    <PaymentAccountHistory/>
                </Col>
            </Row>
        </section>
    )
};

export default Profile;
