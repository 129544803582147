import React, {useMemo, useState} from 'react'
import {Col, Empty, Row, Watermark} from 'antd'
import {AccessLevel, Project, useProjectCardQuery} from "../../generated-types";
import {useMatch, useParams} from "react-router-dom";
import ProjectMembersList from "./ProjectMembersList";
import {useTranslation} from "react-i18next";
import {addTaskContext} from "../Tasks/TaskNew";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../routes/Auth/authContext";
import ProjectFilesList from "../File/ProjectFilesList";
import Spinner from "../Spinner";
import {editTaskContext} from "../Tasks/TaskEditor";
import ProjectHeader, {ProjectTabs} from "./ProjectHeader";
import {routes} from "../../AppRouter";
import TaskViewList from "../Tasks/TaskView/TaskViewList";
import ProjectSettingsPage from "./ProjectSettingsPage";

const ProjectCard: React.FC = () => {
    const {t} = useTranslation();
    const addTaskCtx = useReactiveVar(addTaskContext);

    const match = useMatch(routes.projectTask);

    if (match?.params.taskId) {
        editTaskContext({taskId: match.params.taskId})
    }

    const {id, tab} = useParams();
    const [selectedTab, setTab] = useState<ProjectTabs>(ProjectTabs.tasks); // TODO: вернуть получение из параметров
    const [taskCount, setTaskCount] = useState(0)

    const {data, loading} = useProjectCardQuery({
        variables: {id: id as string},
        onCompleted: (_) => {
        }
    })

    const authInfo = useReactiveVar(authState);

    const project = data?.project as Project

    let content: any=undefined;

    if(project){
        const isAdmin = project.members
            .some(m => m.user.id == authInfo.user.id && m.accessLevel == AccessLevel.Admin)

        addTaskCtx.projectId = project.id;
        addTaskContext(addTaskCtx);

        content = {
            [ProjectTabs.tasks]: <TaskViewList key={project.tasksView.id} tasksView={project.tasksView}
                                               allowCreate={true}
                                               showProjectTitle={false} onTasksFiltered={(_, taskCount) => {
                setTaskCount(taskCount)
            }}/>,
            [ProjectTabs.files]: <ProjectFilesList project={project} isAdmin={isAdmin}/>,
            [ProjectTabs.members]: <ProjectMembersList project={project}/>,
            [ProjectTabs.settings]: <ProjectSettingsPage project={project} isAdmin={isAdmin}/>,
        }
    }

    const watermarkContent = useMemo(() => {
        if (project && project.paymentAccount?.isBlocked) {
            return t('paymentAccount.projectIsBlocked') as string
        } else if (project && project.archived) {
            return t('project.archived')as string
        } else return undefined
    }, [project])

    if (loading)
        return <Spinner loading={loading}/>

    if (!id || !project) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    }

    // У меня никак не получается положить дропдаун ниже так, чтобы он был справа, а название проекта было во всю оставшуюся ширину.
    return <Watermark content={watermarkContent} style={{display: "flex", flexDirection: "column", flexGrow: 1, maxHeight: '100%'}}>
        {project && <ProjectHeader project={project} onChangeTab={tab => setTab(tab)} selectedTab={selectedTab}
		                               taskCount={taskCount}/>}

        <Row style={{margin: 0, flexGrow: 1, overflow: 'hidden', marginTop: 4}}>
            <Col span={24} style={{height: '100%', maxHeight: '100%'}}>
                {content[selectedTab]}
            </Col>
        </Row>
    </Watermark>
};

export default ProjectCard