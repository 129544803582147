import {Alert} from 'antd';
import React from 'react';
import TaskViewList from "./Tasks/TaskView/TaskViewList";
import {TasksView, TasksViewFilterType, useTasksViewsQuery} from "../generated-types";
import {useTranslation} from 'react-i18next';
import Spinner from "./Spinner";
import {CustomPageHeader} from "./AntCastomComponents/PageHeader";

const MyTasks = () => {
    const {t} = useTranslation();

    const { loading, error, data } = useTasksViewsQuery({
        variables: {
        }
    })

    const tw = data?.tasksViews.find(v=>v.filterType == TasksViewFilterType.Personal) as TasksView

    return (<div style={{display: "flex", flexDirection: "column", height: "100%"}}>
        <CustomPageHeader title={t('myTasks')}></CustomPageHeader>
        <Spinner loading={loading}></Spinner>
        {error && <Alert type="error" message={error.message} banner/>}
        {!loading && !error && <TaskViewList tasksView={tw} allowCreate={false}/>}
    </div>)
};

export default MyTasks;