import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import intervalPlural from 'i18next-intervalplural-postprocessor';
import {makeVar, ReactiveVar} from "@apollo/client";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import antEn from 'antd/locale/en_US';
import antRu from 'antd/locale/ru_RU';
import antIt from 'antd/locale/it_IT';
import {Locale} from "antd/lib/locale";
import ICU from "i18next-icu";

dayjs.locale('ru');


i18next
    .use(initReactI18next)
    .use(ICU)
    // .use(intervalPlural)
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: 'ru',
        simplifyPluralSuffix: true,
        resources: {
            // en: {
            //     translation: {
            //         auth: {
            //             signIn: "Sign In",
            //             signUp: "Sign Up",
            //             profile: "Profile",
            //             signOut: "Sign out",
            //             inputUserName: "Please input your username!",
            //             username: "Username",
            //             inputPassword: "Please input your password!",
            //             password: "Password",
            //             inputEmail: "Please input your email!",
            //             email: "Email"
            //         },
            //         submit: "Submit",
            //         noProjectsInList: "There is no projects",
            //         createNewProject: "Create",
            //         projectsList: "Projects",
            //         addUsersToProject: "Add",
            //     },
            //                     profile: {
            //                         userName: "User name",
            //                         email: "Email",
            //                         fullName: "Full name",
            //                         aboutMe: "About me",
            //                         edit: "Edit",
            //                         changePassword: "Change passowrd",
            //                         save: "Сохранить",
            //                         newPassword: "New password",
            //                         repeatPassword: "Repeat password",
            //                         shortInfo: "Short Info",
            //                     }
            // },
            // it: {
            //     translation: {
            //         auth: {
            //             signIn: "Entrata",
            //         },
            //         projectsList: "Progetti",
            //     }
            // },
            ru: {
                translation: {
                    auth: {
                        signIn: "Вход",
                        signUp: "Регистрация",
                        forgotPassword: "Забыли пароль?",
                        restorePassword: "Сохранить и войти",
                        sendRestoreLink: "Отправить ссылку",
                        sendRestoreLinkTitle: "Укажите ваш email, на который отправить ссылку для восстановления пароля",
                        restorePasswordTitle: "Укажите ваш логин и новый пароль.",
                        profile: "Профиль",
                        signOut: "Выйти",
                        inputUserName: "Пожалуйста, введите имя пользователя",
                        username: "Имя пользователя",
                        inputPassword: "Пожалуйста, введите свой пароль",
                        password: "Пароль",
                        inputEmail: "Пожалуйста, введите существующий email",
                        email: "Почта"
                    },
                    submit: "Отправить",
                    searchLeftManu: "Поиск",
                    noProjectsInList: "У вас нет проектов",
                    sort: "Сортировка",

                    filter: "Фильтр",
                    filters: "Фильтры",
                    filtersClear: "Очистить",
                    filterShow: "Показывать",
                    filterPriority: "Приоритет",
                    filterUsersUnion: "Команда",
                    filterTaskMember: "Исполнитель",
                    filterTags: "Метки",
                    filterProject: "Проект",
                    filterFlags: {
                        onlyMy: "Только мои задачи",
                        includeResolved: "Завершённые задачи",
                        includeUnresolved: "Незавершённые задачи",
                    },
                    projectsFilter: {
                        filterShow: "Показывать проекты",
                        filterUsersUnion: "Команда",
                        filterMember: "Участники",
                        filterTags: "Метки",
                        filterFlags: {
                            iamMember: "Я участвую",
                            active: "Активные",
                            archived: "В архиве",
                            invites: "Приглашения",
                        },
                    },
                    myTasks: "Мои задачи",
                    allProjects: "Главная",
                    mainPage: "Все команды и проекты",
                    projectsCount_zero: "нет проектов",
                    projectsCount_one: "{count} проект",
                    projectsCount_two: "{count} проекта",
                    projectsCount_few: "{count} проекта",
                    projectsCount_many: "{count} проектов",
                    search:"Поиск по проектам и командам",
                    notifications: "Уведомления",
                    techHelp: "Тех.поддержка",
                    createNewProject: "Новый",
                    projectsList: "Проекты",
                    members: "Участники",
                    tasks: "Задачи",
                    resolvedTasks: "Завершенные задачи",
                    userSearch: {
                        inviteMember: "Пригласить участника",
                        sendInvite: "Отправить приглашение",
                        addMembers: "Добавление участников",
                        added: "Добавлено",
                        inviteMemberToProject: "Приглашение участника в проект: {title}",
                        inviteMemberToProjectText: "Участник, которому вы отправляете приглашение в проект, должен подтвердить его. После подтверждения участнику можно будет назначить роль.",
                        addMembersText: "Вы можете добавить {membersCount, plural, =0{# участников} =1{# участника} many{# участников} other{# участника}}. Чтобы пригласить больше людей, нужно сменить тариф или добавить слоты",
                        AutoCompletePlaceholder: "Поиск пользователя",
                        choiceUsers: "Найди пользователя по имени или имейлу",
                        choiceUsersAndUnions: "Найди пользователя по имени или имейлу или найти команду по названию",
                    },
                    addUsersToProject: "Пригласить",
                    aMemberInProjectOfUser: "Пользователь ",
                    aMemberInProjectOfUsersUnion: "Команда ",
                    user: "Пользователь",
                    project: {
                        headerTitle: "Проект:",
                        headerBoard: "Статус",
                        headerAddTagsButton: "Добавить метку",
                        mobileMenu: "Действия с проектом",
                        chooseStatus: "Выберите статус",
                        showDetails: "Детали проекта",
                        transfer: "Изменить владельца",
                        transfer2: "Передать проект",
                        filesInProject: "Количество файлов в проекте",
                        userNotInProject: "Не участвует в проекте. Но он является участником команды и может быть назначен на задачу",
                        userNotInProjectTitle: "Не в проекте",
                        transferTooltip: "Передача проекта другому пользователю или команде",
                        transferWaiting: "Проект передаётся: ",
                        transferSelectedText: "Передать проект",
                        transferAcceptRejectInUsersUnion: "передаёт в команду {title} проект {name}",
                        transferAcceptRejectInPersonal: "передаёт вам проект {name}",
                        transferRejectTitle: "Отклонить передачу проекта?",
                        transferModalText: "Пользователь, которому вы передаете проект, должен подтвердить передачу проекта. Только после этого освободятся слоты, занимаемые участниками этого проекта.",
                        transferRejectBody: "",
                        createWindowTitle: "Новый проект",
                        leave: "Покинуть",
                        leaveTooltip: "Покинуть этот проект",
                        archivingTooltip:"Отправить проект в архив",
                        leaveConfirmTitle: "Подтвердите.",
                        leaveConfirmDescription: "Покинуть этот проект?",
                        leavedSuccessfully: "Вы покинули проект.",
                        archiving: "Архивировать",
                        archiveTooltip:"Отправить проект в архив. Он перестанет отображаться в списке проектов. Также освободятся слоты. Но проект будет доступен только для просмотра",
                        unArchiving:"Вернуть из архива",
                        archived:"Проект в архиве",
                        activeProjects:"Активные",
                        archivedProjects:"В архиве",
                        showArchivedProjects:"Показать проекты в архиве",
                        archivingConfirmDescription:"Отправить проект в архив",
                        unArchivingConfirmDescription:"Вернуть из архива этот проект?",
                        joinYourselfToProject: "Присоединиться",
                        descriptionPlaceholder: "Описание проекта",
                        titlePlaceholder: "Нужно дать название проекту",
                        title: "Название",
                        dates: "Даты проекта",
                        description: "Описание",
                        isPublic: "Публичный",
                        cover: "Обложка",
                        addCover: "выбрать",
                        create: "Создать проект",
                        noMembers: "",
                        tasks: "Задачи",
                        files: "Файлы",
                        members: "Участники",
                        settings: "Настройки",
                        settingsPage: {
                            info: "Информация о проекте",
                            projectOwner: "Владелец проекта",
                            tags: "Управление метками",
                            icon: "Иконка",
                            color: "Цвет",
                            archive: "Архивирование проекта",
                            selectColor: "Выбрать цвет",
                            archiveDescription: "Проект будет архивирован вместе со всеми задачами, он перестанет отображаться в списке проектов. Также освободятся слоты. Проект будет доступен только для просмотра"
                        },
                        AddToFavorite: "Добавить в избранные",
                        RemoveFromFavorite: "Убрать из избранных",
                        invitesList: "Приглашения",
                        save: "Сохранить",
                        saveChanges: "Сохранить изменения",
                        acceptInvite: "Принять",
                        declineInvite: "Отклонить",
                        status: {
                            new: 'Новый',
                            active: 'В работе',
                            finished: 'Готовый',
                            frozen: 'Отложенный'
                        },
                        inviteToProject: "пригласил вас в проект",
                        inviteToProjectAcceptTitle: "Принять",
                        inviteToProjectDeclineTitle: "Отклонить",
                        inviteToProjectDeclinePopupText: "",
                        inviteToProjectDeclinePopupTitle: "Отклонить приглашение в проект?",
                    },
                    countInvitesUnionMembers:"Максимум участников от команды: {count}",
                    projectMembers: {
                        SureRemove: "Удалить участника проекта?"
                    },
                    yes: "Да",
                    no: "Нет",
                    decline: "Отклонить",
                    remove: "Удалить",
                    cancel: "Отмена",
                    invitedByEmail: "Приглашение отправлено по эл.почте",
                    invitedUser: "Приглашение отправлено",
                    invitedUsersUnion: "Приглашение отправлено владельцу команды",
                    really: "Действительно?",
                    beCaution: "Будьте очень внимательны!",
                    inviteSureRemove: "Отменить приглашение?",
                    otherUsersProjects: "Персональные проекты",
                    otherUsersUnionsProjects: "Проекты других команд",
                    personalProjects: "Мои проекты",
                    favoriteProjects: "Избранные проекты",
                    membersList: {
                        search: "Поиск участника по имени, логину или по эл. почте",
                        searchNotFound: "Поиск не дал результатов. Попробуйте ввести имя пользователя или имя команды целиком или эл. почту чтобы пригласить",
                        sendEmailInvite: "Приглашение по эл.почте",
                        selectedInvites: "Получатели приглашений",
                        userUnionPlaces: "Мест для команды",
                        emptyLeaved: "Вы можете добавить {empty} участников",
                        youNotMemberOfAUsersUnion: "Вы не участник команды",
                        notEnoughEmpty: "Не хватает мест для новых участников",
                    },
                    usersUnion: {
                        leave: "Покинуть",
                        delete: "Удалить команду",
                        deletedConfirmation: "Команда будет удалена без возможности восстановления.",
                        deletedSuccessfully: "Команда удалена",
                        leaveTooltip: "Покинуть эту команду",
                        leaveConfirmTitle: "Подтвердите.",
                        leaveConfirmDescription: "Покинуть эту команду?",
                        leavedSuccessfully: "Вы покинули команду.",
                        create: "Создать команду",
                        newInvite: "приглашает в команду",
                        createWindowTitle: "Новая команда",
                        title: "Название",
                        info: "Информация о команде",
                        titlePlaceholder: "Обязательно укажите название команды",
                        name: "Короткое имя",
                        namePlaceholder: "Имя команды для поиска",
                        onlyNumbersAndSymbols: "Только буквы латинского алфавита и цифры. Первый символ - буква.",
                        minSix: "Минимум 6 символов.",
                        oneHundredMax: "Максимум 100 символов.",
                        nameIsBusySelectAnother: "Имя уже занято. Пожалуйста, выберите другое",
                        description: "Описание",
                        descriptionPlaceholder: "Для удобства добавьте описание",
                        projects: "Проекты",
                        tasks: "Задачи",
                        files: "Файлы",
                        members: "Участники",
                        settings: "Настройки",
                        team: "Команда",
                        teams: "Команды",
                        projectInvites: "Приглашения",
                        projectInvitesManage: "Управление участниками проекта от команды",
                        projectsInvitesLink: "{count, plural, =0{# приглашений} =1{одно приглашение} many{# приглашений} other{# приглашения}}",
                        SureRemoveMember: "Удалить участника команды?",
                        paymentAccount:"Владелец",
                        countInvitesMembers: "{count}",
                        processProjectInvite: {
                            title: "Выберите участников команды для участия в проекте",
                            searchPlaceholder: "Поиск...",
                            itemUnit: "участник",
                            remove: "remove",
                            selectAll: "Отметить всех",
                            itemsUnit: "участников",
                            selectCurrent: "Отметить текущего",
                            selectInvert: "Инвертировать отметки",
                            removeAll: "Исключить всех",
                            removeCurrent: "Исключить текущего",
                            maxMembers:"Вы можете пригласить {count} участников.",
                            notOwnerMessage:"Недостаточно слотов. Обратись к владельцу проекта.",
                            addSlots:"Добавить слотов"
                        }
                    },
                    task: {
                        columns: {
                            todo: "Сделать",
                            wip: "В работе",
                            done: "Готово",
                        },
                        datesSelector: {
                            placeholder: "Запланировать",
                            singleDay: "Один день",
                            fewDays: "Несколько дней",
                            schedule: "Запланировать",
                        },
                        membersList: {
                            label: "Исполнители",
                            addProjectMember: "Добавить участников проекта",
                            addMembers: "Добавить исполнителей",
                            search: "Поиск",
                            removeMember: "Убрать исполнителя",
                            choiceMember: "Выберите исполнителя",
                        },
                        downloadFile: "Скачать файл",
                        childTasks: "Подзадачи",
                        addChildTask: "Добавить подзадачу",
                        create: "Создать задачу",
                        addTask: "Новая задача",
                        save: "Сохранить задачу",
                        description: "Описание задачи",
                        title: "Название задачи",
                        titleRequired: "Нужно дать название задаче",
                        importance: "Важность задачи",
                        oneDay: "Дата",
                        startDate: "Начало",
                        endDate: "Дедлайн",
                        startTime: "С",
                        endTime: "До",
                        resolve: "Выполнить",
                        resolved: "Выполнена",
                        createWindowTitle: "Новая задача",
                        editWindowTitle: "Изменение задачи",
                        editMembers: "Добавить",
                        hideEditMembers: " ",
                        responsible: "Ответственный",
                        removeMember: "Убрать участника",
                        members: "Участники",
                        noMembers: "Добавить участников",
                        files: "Файлы",
                        addFiles: "Прикрепить файлы",
                        filesConnectDisk: "Подключите папку Яндекс Диска к проекту, чтобы работать с файлами",
                        responsiblePlaceholder: "Выберите из участников проекта",
                        membersPlaceholder: "Выберите из участников проекта",
                        tasksAsList: "Список",
                        tasksAsListTooltip: "Показать список задач в виде списка",
                        tasksAsBoard: "Доска",
                        tasksAsBoardTooltip: "Показать список задач в виде доски",
                        includeResolved: "Выполненные",
                        iAmResponsible: "Мои",
                        allTasks: "Все",
                        removeTask: "Удалить задачу",
                        confirmRemoveTaskTitle: "Удаление задачи",
                        confirmRemoveTaskDescription: "Вы уверены, что хотите удалить задачу",
                        resolvedTrue: "Выполненные",
                        resolvedFalse: "Выполненные",
                        currentTask: "Близок дедлайн",
                        deadlineOverdue: "Дедлайн остался за бортом",
                        plannedDate: "Задача запланирована",
                        tasksViewFilterByTags: "Фильтр по меткам",
                        search:"Поиск по задачам, ответственным, проектам, командам",
                        task: "Задача",
                        copyLink: "Скопировать ссылку",
                        copyLinkSucces: "Ссылка скопирована",
                        copyLinkError: "Ошибка копирования ссылки",
                        addMoreInformation: 'Указать дополнительные сведения',
                        sortFields: {
                            columnASC: "Сначала не начатые",
                            columnDESC: "Сначала завершённые",
                            titleASC: "По алфавиту А-Я",
                            titleDESC: "По алфавиту Я-А",
                            responsibleASC: "Ответственный А-Я",
                            responsibleDESC: "Ответственный Я-А",
                            importanceASC: "Сначала с низким приоритетом",
                            importanceDESC: "Сначала с высоким приоритетом",
                            deadlineASC: "Сначала несрочные",
                            deadlineDESC: "Сначала срочные",
                            createDateASC: "Сначала старые",
                            createDateDESC: "Сначала новые",

                            title: "Название",
                            responsible: "Ответственный",
                            column: "Статус",
                            deadline: "Дедлайн",
                            importance: "Важность",
                            position: "Положение в колонке",
                        },
                        displayType:{
                            list:"Список",
                            board:"Доска",
                            calendar:"Календарь"
                        },
                        status: {
                            todo: "Сделать",
                            wip: "В работе",
                            done: "Готово"
                        },
                        priority: {
                            withoutPriority: 'Без приоритета',
                            low: "Низкий",
                            medium: "Средний",
                            critical: "Критический",
                            prioritySelectTitle: "Выберите приоритет"
                        },
                        taskslistColumnsTitles:{
                            status: "Доска",
                            title: "Наименование",
                            priority: "Приоритет",
                            members: "Исполнитель",
                            deadline: "Дата исполнения",
                            tags: "Метки"
                        }
                    },
                    events: {
                        view: "Вид",
                        displayAll: "Все действия",
                        displayAllTooltip: "Все действия",
                        displayMessages: "Комментарии",
                        displayMessagesTooltip: "Комментарии",
                        displayEvents: "История",
                        displayEventsTooltip: "История",
                        addComment: "Комментарий",
                        showHistory: "Показать историю",
                        hideHistory: "Скрыть историю",
                        you: 'Вы',
                        user: 'Пользователь',
                        taskChanged: {
                                created: "{actor, select, you{создали задачу} user {создал задачу} other {задача создана}}",
                                addTag: "{actor, select, you {добавили тег} user {добавил тег} other {добавлен тег}} {tags}",
                                removeTag: "{actor, select, you {удалили тег} user {удалил тег} other {удален тег}} {tags}",
                                addFile: "{actor, select, you {добавили файл} user {добавил файл} other {добавлен файл}} {files}",
                                removeFile: "{actor, select, you {удалили файл} user {удалил файл} other {удален файл}} {files}",
                                addMember: "{actor, select, you {добавили исполнителя} user {добавил исполнителя} other {добавлен исполнитель}} {members}",
                                removeMember: "{actor, select, you {удалили исполнителя} user {удалил исполнителя} other {удален исполнитель}} {members}",
                                descriptionChange: "{actor, select, you {изменили описание} user {изменил описание} other {описание изменено}} на \"{value}\"",
                                endDateChange: "{actor, select, you {изменили} user {изменил} other {изменено}} endDateChange {value}",
                                endTimeChange: "{actor, select, you {изменили} user {изменил} other {изменено}} endTimeChange {value}",
                                fieldChange: "{actor, select, you {изменили} user {изменил} other {изменено}} fieldChange {value} {additional}",
                                importanceChange: "{actor, select, you {изменили} user {изменил} other {изменено}} importanceChange {value}",
                                resolvedChange: "{actor, select, you {изменили} user {изменил} other {изменено}} resolvedChange {value}",
                                startDateChange: "{actor, select, you {изменили} user {изменил} other {изменено}} startDateChange {value}",
                                startTimeChange: "{actor, select, you {изменили} user {изменил} other {изменено}} startTimeChange {value}",
                                titleChange: "{actor, select, you {изменили} user {изменил} other {изменено}} заголовок  на \"{value}\"",
                                taskTime: "{actor, select, you {Ваше время по задаче} user {Время по задаче} other {Время по задаче}} +{value}ч",
                                taskEstimation: "{actor, select, you {Ваша оценка по времени выполнения} user {Оценка по врменени выполнения} other {Оценка по врменени выполнения}} {value}ч",
                            }
                    },
                    accessLevel: {
                        admin: "Админ",
                        member: "Участник",
                    },
                    projectInvites: {
                        declineInviteTitle: "Приглашение в проект",
                        declineInviteBody: "Отклонить приглашение?",
                    },
                    usersUnionInvites: {
                        declineInviteTitle: "Приглашение в команду",
                        declineInviteBody: "Отклонить приглашение?",
                    },
                    calendar: {
                        today: "Сегодня"
                    },
                    file: {
                        noConnectedStorage: "Прикрепите папку Яндекс Диска, чтобы работать с файлами",
                        connectYandexDisk: "Подключить Я.Диск",
                        attachButton: "Прикрепить",
                        projectAttachFolderBtn: "Прикрепить папку",
                        usersUnionAttachFolderBtn: "Прикрепить папку",
                        projectAttachFolder: "Прикрепить папку к проекту",
                        usersUnionAttachFolder: "Прикрепить папку к команде",
                        attachFolderHeader: "Прикрепить папку",
                        attachedFolders: "Прикреплённая папка",
                        changeAttachedFolder: "Изменить папку",
                        adminHasToAttachFolder: "Админ должен прикрепить папку, чтобы работать с файлами",
                        attach: "Прикрепить",
                        detach: "Открепить",
                        filesInTasks: "Файлы в задачах",
                        attachFilesFromFolder: "Прикрепить файлы из папки \"{folderName}\"",
                        name: 'Название',
                        format: 'Формат',
                        size: 'Размер',
                        b: 'б',
                        Kb: 'кб', 
                        Mb: 'мб', 
                        Gb: 'гб',
                        isntAdminAttachMessage: "Cейчас проект не настроен для работы с файлами. Если вам требуется работать с файлами, запросите у администратора проекта подключить эту возможность.",
                        isAdminAndNoYandexDiskAttachMessage: "Чтобы работать с файлами в проекте, зайдите в раздел \"файлы\" в заголовке проекта. Подключите яндекс-диск и укажите папку для этого проекта на подключенном яндекс-диске.",
                        isAdminAndNoFolderAttachMessage: "У вас уже подключен яндекс диск к проекту. Вам требуется только указать папку. Для этого зайдите в раздел \"файлы\" в заголовке проекта и укажите нужную папку."
                    },
                    tags: {
                        add: "Новая метка",
                        addText: "Введите текст метки",
                        stillAdded: "Добавлены",
                        editTaskTags: "Редактирование меток",
                        projectEditorTitle: "Управление метками проекта",
                        usersUnionEditorTitle: "Управление метками команды",
                        personalEditorTitle: "Управление личными метками",
                        removeConfirmTitle: "Удалить метку?",
                        remove: "Удалить метку",
                        taggerPlaceholder: "Добавить метки",
                        saveNewTag: "Сохранить новую метку",
                        tagDelitionErrorMessage: "Не удалось удалить метку",
                        search: "Поиск меток",
                        addProjectTag: "Добавить метки",
                        createAndEdit: "Создание и редактирование меток",
                        notFound: "Метка не найдена",
                        projectTags: "Метки проекта",
                        unionTags: "Метки команды",
                        personalTags: "Персональные метки",
                        youCantEditTags: "Вы не можете редактировать метки этого проекта"
                    },
                    profile: {
                        userName: "Имя пользователя",
                        fullName: "Полное имя",
                        email: "Эл. почта",
                        aboutMe: "Обо мне",
                        edit: "Редактировать",
                        changePassword: "Сменить пароль",
                        save: "Сохранить",
                        newPassword: "Новый пароль",
                        repeatPassword: "Подтвердить пароль",
                        shortInfo: "Краткая подпись",
                        noFullName: "Пользователь еще не добавил полное имя"
                    },
                    youCantEditYourselfInProjectMembers: "Вы не можете изменить или удалить себя из проекта. Это может сделать другой админ",
                    youCantEditYourselfInUsersUnionMembers: "Вы не можете изменить или удалить себя из команды. Это может сделать другой админ",
                    noProjects: "Нет проектов",
                    termsOfUse: "Условия использования",
                    contacts: "Контакты",
                    tariffsTable:{
                        title:"Тарифы",
                        personalProjectTitle:"Личные проекты",
                        usersUnionTitle:"Команды",
                        freeCost:"Бесплатно",
                        period:"мес",
                        numberOfPersonalProjects:{
                            title:"Количество личных проектов",
                            number:"∞"
                        },
                        personalProjectMembersFreeCount:"Бесплатных приглашений и участников",
                        personalProjectAdditionalMemberAdditionalCost:"Дополнительное приглашение",
                        personalProjectNumberBeOnTeam:{
                            title:"Состою в проектах",
                            number:"∞"
                        },
                        usersUnionFreeCount:"Бесплатные",
                        usersUnionAdditionalCost:"Дополнительная команда",
                        usersUnionMembersFreeCount:"Бесплатные участники и приглашения в командах",
                        usersUnionMembersAdditionalCost:"Дополнительный участник команды",
                        usersUnionProjectMemberFreeCount:"Бесплатные участники в проектах команд",
                        usersUnionProjectMemberAdditionalCost:"Дополнительный участник проекта команд",
                        usersUnionCanInvolvedInProjects:{
                            title:"Участие команды в проектах",
                            number:"∞"
                        },
                        button:"Выбрать"
                    },
                    cookieConsent: {
                        mainText: "Мы используем файлы cookie, чтобы обеспечивать правильную работу нашего веб-сайта и анализировать сетевой трафик.",
                        linkText: "Согласие на обработку и политика в отношении персональных данных",
                        acceptButton: "Согласен"
                    },
                    paymentAccount:{
                        currentTariff:"Текущий тариф",
                        history:"Счета",
                        change:"Сменить",
                        changeTariff:"Сменить тариф",
                        period:"Ежемесячно:",
                        payNaw:"Сейчас оплатить:",
                        paymentDate:"Следующий платеж:",
                        freeCost:"В тарифе",
                        paid:"Дополнительные",
                        personalProjectAdditionalMember:"Участники в личные проекты",
                        usersUnion:"Команды",
                        usersUnionMembers:"Участники команд'",
                        usersUnionProjectMember:"Участники командных проектов",
                        remove:"Удалить",
                        add:"Добавить",
                        total:"Всего",
                        used:"Используется",
                        addSlots:"Приобрести дополнительные слоты",
                        return:"Вернутся",
                        pay:"Оплатить",
                        paymentSuccessful:"Платеж прошел успешно",
                        paymentFail:"Произошла ошибка",
                        paymentFailHelp:"Перейдите по ссылке, что бы мы могли вам помочь",
                        projectIsBlocked:"Проект заблокирован",
                        unionIsBlocked:"Команда заблокирована",
                        wasPaymentDate:"Дата платежа",
                        currency:"Валюта",
                        amount:"Сумма",
                        receiptOf:"Счет ",
                        promisePay:"Не удалось списать оплату. Пожалуйста оплатите до {data}",
                        isBlocked:"Ваши проекты и команды заблокированы. Пожалуйста, оплатите счет. ",
                        receiptSuccess: "Оплачен",
                        receiptPending: "Обрабатывается",
                        receiptCanceled: "Отменён",
                    },
                    errorModal:{
                        errorTariffUnions:"На тарифе Хобби вы можете создавать не более одной команды."
                    },
                    editDataMassage:"Информация обновлена"

                }
            }
        }
    })

export const changeLanguage = (lang: string) => {
    lang = "ru" // TODO: изменить, когда будут еще языки
    i18next.changeLanguage(lang)
    dayjs.locale(lang);
    selectedLanguage(languages.find(v=>v.key === lang) || ru)
}

export class LanguageSettings {
    key!: string;
    title!: string
    locale!: Locale
}


export const en: LanguageSettings = {
    key: "en",
    title: "English",
    locale: antEn
}

export const ru: LanguageSettings = {
    key: "ru",
    title: "Русский",
    locale: antRu
}

export const it: LanguageSettings = {
    key: "it",
    title: "Italiano",
    locale: antIt
}

export const selectedLanguage: ReactiveVar<LanguageSettings> = makeVar<LanguageSettings>(ru);

export const languages = [ru, en, it]