import React, {useMemo, useState} from 'react';
import {Button, message, Modal, Progress, theme} from 'antd';
import {
    AccessLevel,
    PaymentSlot,
    PaymentSlotType,
    Project,
    useProjectAddMembersMutation,
    User,
    UsersUnion,
    useUserPaymentAccountQuery
} from "../../generated-types";
import UserSearch, {IInvite, UserOrUnionInvite, UUInvite} from "../User/UserSearch";
import {useTranslation} from "react-i18next";
import {authState} from "../../routes/Auth/authContext";
import {useReactiveVar} from "@apollo/client";
import {slotsTypesWithAmounts} from "../Payment/SlotsTypesWithAmounts";
import {CountInvitesMembers} from "../CountInvitesMembers";
import {updateProjectInCache} from "../../subscriptions/allProjects";
import styled, {css} from "styled-components";
import {IconAttantion} from "../Icon/IconAttantion";
import {IconPlus} from "../Icon/IconPlus";
import Text from "antd/es/typography/Text";
import {addSlotsContext} from "../Payment/AddSlotsModal";

interface ProjectMembersEditorProps {
    project: Project
    totalMembers: number
}

const ModelContent = styled.div<{}>`
    min-height: 500px;
    display: flex;
    flex-direction: column;

    .txt2 {
        padding-top: 8px;
        padding-bottom: 8px;
        ${p => !p.theme.isMobile && css`
            width: 70%;
        `}

        font-weight: normal;
    }

    .title {
        font-size: 20px;
    }
`

const Holder = styled.div<{}>`
    ${p => !p.theme.isMobile ? css`margin: 40px 24px;` : css`margin: 16px 8px;`}
    max-width: 800px;
    display: flex;
    border-radius: 4px;
    background-color: ${p => p.theme.colors.ui.white};

    ${p => !p.theme.isMobile && css`
        height: 109px;
        gap: 16px;
        flex-direction: row;
    `}
    ${p => p.theme.isMobile && css`
        flex-direction: column;
    `}
    
    .invite-member {
        background-color: ${p => p.theme.colors.ui.bgLight};
        color: ${p => p.theme.colors.font.accent};
        border: 0;
        height: 44px;
    }
    
    .block1 {
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 15px;
        line-height: 115%;
    }

    .title {
        font-size: 16px;
    }
    
    .block2 {
        display: flex;
        align-items: center;
        border-radius: 8px;
        margin: 8px;
        padding: 16px;
        gap: 8px;
        color: ${p => p.theme.colors.font.primary};
        background-color: ${p => p.theme.colors.font.red01};
        overflow: hidden;
        
        div {
            align-self: self-start;
        }
    }

    .block2 :first-child {
        color: ${p => p.theme.colors.font.red};
    }

    .block3 :first-child {
        display: flex;
    }
    
    .block3 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px;
    }
`


const ProjectMembersEditor2: React.FC<ProjectMembersEditorProps> = ({project, totalMembers}) => {
    const {t} = useTranslation()
    const authInfo = useReactiveVar(authState);
    const [messageApi, contextHolder] = message.useMessage();
    const [open, setOpen] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<(UserOrUnionInvite)[]>([]);
    const {token} = theme.useToken();

    const [Add, opt1] = useProjectAddMembersMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        },
        onCompleted: () => {
            setSelectedItems([])
            setOpen(false)
        }
    });
    // const [ChangeLevel, opt2] = useProjectMemberChangeLevelMutation({});
    // const [Remove, opt3] = useProjectRemoveMemberMutation({
    //     update: (cache, r) => {
    //         updateProjectInCache(cache, r.data?.changedProject as Project)
    //     }
    // });
    const {data: {paymentAccount} = {payment: null}, loading} = useUserPaymentAccountQuery({
        variables: {id: project.paymentAccount.id}
    });
    // const [RemoveInvite] = useProjectMemberInviteRemoveMutation({
    //     update: (cache, r) => {
    //         updateProjectInCache(cache, r.data?.changedProject as Project)
    //     }
    // });

    const isAdmin = project.members.some(m => m.user.id === authInfo.user.id && m.accessLevel === AccessLevel.Admin)
    const slots = useMemo(() => (paymentAccount?.slots as PaymentSlot[]), [paymentAccount]);

    let type = '';

    if (project.userAsOwner) {
        type = 'personalProjectAdditionalMember'
    }
    if (project.usersUnionAsOwner) {
        type = 'usersUnionProjectMember'
    }

    const [allSlots, empty] = useMemo(() => {
        const s = Object.keys(PaymentSlotType)
            .map(k => slotsTypesWithAmounts(slots as any ?? [], k))
        const membersSlots = s.find((s) => s.type === type);
        const e = membersSlots?.payEmptySlots.length! + membersSlots?.freeEmptySlots.length!
        return [s, e]
    }, [slots]);

    const user = paymentAccount?.managers.find((m) => m.id === authInfo.user.id)

    const openAddSlotsModal = () => {
        const allSlots = paymentAccount?.slots
        const slotsType = Object.keys(PaymentSlotType)
            .map(k => slotsTypesWithAmounts(allSlots!, k))
        addSlotsContext({
            slotsType: slotsType
        });
    }

    const totalSelected = useMemo(() => {
        return selectedItems.map(v => {
            if (v.__typename == "UsersUnion")
                return (v as UUInvite).maxMembers
            return 1;
        }).reduce((prev, cur) => prev + cur, 0);
    }, [selectedItems])

    if (!isAdmin)
        return <></>;

    const modalContent = <ModelContent>
        <div className={"title"}>{t('userSearch.inviteMemberToProject', {title: project.title})}</div>
        <div className={"txt2"}>{t('userSearch.inviteMemberToProjectText')}</div>

        <div style={{flexGrow: 1}}>
            <UserSearch
                initialState={selectedItems}
                choiceTeamSlots={true}
                selectedText={t('membersList.selectedInvites')}
                maxMembers={empty - totalMembers}
                project={project}
                disabled={project.paymentAccount?.isBlocked || project.archived}
                addUsersUnions={true}
                onChange={(selected) => {
                    setSelectedItems(selected);
                }}
                members={project.members.map(v => v.user)}
                invites={project.invites.map(i => (
                    {email: i.email, user: i.user, usersUnion: i.usersUnion} as IInvite)
                )}/>
        </div>

        <div style={{display: "flex", gap: 8, alignItems: "center"}}>
            <Button type={"primary"} disabled={selectedItems.length > empty || selectedItems.length == 0}
                    onClick={() => {
                        if (selectedItems.length <= empty) {
                            const uu1 = selectedItems.find(v => v.__typename == "UsersUnion") as UUInvite;
                            const input = {
                                projectId: project.id,
                                maxMembers: (uu1?.maxMembers ?? 1),
                                users: selectedItems.filter(v => v.__typename == "User").map(v => v.id) as string[],
                                unions: selectedItems.filter(v => v.__typename == "UsersUnion").map(v => v.id) as string[],
                                emails: selectedItems.filter(v => v.__typename == "User" && v.id.indexOf('@') > 0).map(v => (v as User).id) as string[]
                            };

                            Add({variables: {input}})
                            return true;
                        } else {
                            addSlotsContext({
                                slotsType: allSlots
                            });
                            return false
                        }
                    }}>
                {t(('userSearch.sendInvite'))}
            </Button>

            <span style={{color: token.colors.font.primarySemitransparent}}>
                {empty >= totalSelected && t('membersList.emptyLeaved', {empty: empty - totalSelected})}
                {empty < totalSelected && t('membersList.notEnoughEmpty', {empty: totalSelected - empty})}
            </span>

            {user && empty < totalSelected &&
							<Button style={{marginTop: 12}} type={'primary'} onClick={() => openAddSlotsModal()}>
                  {t('usersUnion.processProjectInvite.addSlots')}
							</Button>
            }
            {!user && empty < totalSelected &&
							<Text style={{marginTop: 12, display: 'block'}} strong type={'danger'}>
                  {t('usersUnion.processProjectInvite.notOwnerMessage')}
							</Text>
            }
        </div>
    </ModelContent>;

    const res = <Holder>
        <div className={'block1'}>{t('userSearch.addMembers')}</div>
        <div className={'block2'}>
            <div><IconAttantion/></div>
            <div>{t('userSearch.addMembersText', {membersCount: empty-totalMembers })}</div>
        </div>
        <div className={'block3'}>
            <div>
                <div style={{flexGrow: 1}}>{t('userSearch.added')}</div>
                <div style={{display: "inline-flex"}}>
                    {totalMembers}/
                    {slots && <CountInvitesMembers slots={slots} type={type[0].toUpperCase() + type.slice(1)}/>}
                </div>
            </div>
            <Progress size={{height: 4}} percent={totalMembers / empty * 100}
                      strokeColor={[token.colors.ui.accent, token.colors.ui.bgLight]} showInfo={false}/>
            <Button className={'invite-member'} onClick={() => setOpen(true)}>
                <IconPlus/>
                {t(('userSearch.inviteMember'))}
            </Button>
        </div>

        <Modal
            open={open}
            onCancel={() => {
                setOpen(false);
            }}
            cancelText={t('paymentAccount.return')}
            okText={t('paymentAccount.pay')}
            width={1000}
            cancelButtonProps={{style: {display: 'none'}}}
            okButtonProps={{style: {display: 'none'}}}
            centered
        >
            {modalContent}
        </Modal>
    </Holder>;


    return <div>{res}</div>
};

export default ProjectMembersEditor2;