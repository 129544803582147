import React, {useEffect, useMemo, useState} from 'react';
import {Input, Space} from 'antd';
import {Maybe, Project, useFindUsersQuery, User, UsersUnion} from "../../generated-types";
import {useTranslation} from "react-i18next";
import * as EmailValidator from 'email-validator';
import {ProjectUnionInvitesEditor} from "../Project/ProjectUnionInvitesEditor";
import {IconSearch} from "../Icon/IconSearch";
import styled from "styled-components";
import UserOrUnionListItem from "./UserOrUnionListItem";
import {IconClose} from "../Icon/IconClose";

export interface IInvite {
    user?: Maybe<User>;
    usersUnion?: UsersUnion;
    email: string;
}

export interface UUInvite extends UsersUnion {
    maxMembers: number
}

export interface UserInvite extends User {
}

export type UserOrUnionInvite = UserInvite | UUInvite

export type UserOrUnion = User | UsersUnion

interface userSearchProps {
    maxMembers?: number,
    project?: Project,
    addUsersUnions: boolean,
    /**
     * Нужно ли добавлять пользователь по имейлу
     */
    addEmails?: boolean,
    membersAdded?: (result: UserSearchResults) => boolean
    onChange?: (result: UserOrUnionInvite[]) => void
    members: Maybe<UserOrUnionInvite>[]
    initialState?: UserOrUnionInvite[]
    invites: Maybe<IInvite>[]
    disabled?: boolean
    checkSlots?: boolean
    /**
     * Можно выбрать только одного пользователя или команду
     */
    onlyOne?: boolean
    /**
     * Добавлять или нет выбор количество слотов для команд
     */
    choiceTeamSlots?: boolean
    selectedText?: string
}

export interface UserSearchResults {
    users: User[],
    unions: UUInvite[]
    emails: string[]
}

const ListHolder = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    .selected-divider {
        margin-top: 20px;
        border-bottom: 1px solid ${p => p.theme.colors.ui.bgLight3};
    }

    .not-found {
        padding: 10px;
        color: ${p => p.theme.colors.font.primarySemitransparent};
    }
`

const UserSearch: React.FC<userSearchProps> = ({
                                                   membersAdded,
                                                   invites,
                                                   members,
                                                   addUsersUnions,
                                                   addEmails = true,
                                                   initialState,
                                                   onChange,
                                                   onlyOne,
                                                   choiceTeamSlots,
                                                   selectedText
                                               }) => {
    const [value, setValue] = useState<UserOrUnionInvite[]>(initialState ?? []);
    const [search, setSearch] = useState<string>("");
    const {t} = useTranslation()

    useEffect(() => {
        if (initialState && initialState.length != value.length)
            setValue(initialState)
    }, [initialState]);

    const {data, loading} = useFindUsersQuery({
        variables: {search}
    });

    const users: UserOrUnionInvite[] = useMemo(() => {
        let res: UserOrUnionInvite[] = []
        if (data && data.findUsers)
            res = data.findUsers
                .filter(v =>
                    members.find(i => i != null && i.id == v.id) == null
                    &&
                    invites.find(i => i != null && i.user?.id == v.id) == null
                ) as User[]

        if (addUsersUnions) {
            if (data && data.findUsersUnions.length) {
                res = res.concat(data.findUsersUnions
                    .filter(v =>
                        invites.find(i => i != null && i.usersUnion?.id == v.id) == null
                    ) as UUInvite[])
            }
        }

        res = res.filter(u => !value.some(u2 => u2.__typename == u.__typename && u2.id == u.id))

        return res;

    }, [data, value]);

    useEffect(() => {
        if (membersAdded) {
            const res: UserSearchResults = {
                users: value.filter(v => v.__typename == "User") as User[],
                unions: value.filter(v => v.__typename == "UsersUnion") as UUInvite[],
                emails: value.filter(v => v.__typename == "User" && v.username.indexOf('@') > 0).map(v => (v as User).username) as string[],
            }
            membersAdded(res);
        }
        if (onChange && value != initialState)
            onChange(value)
    }, [value]);

    const onSelect = (u: UserOrUnionInvite) => {
        if (onlyOne)
            setValue([u])
        else
            setValue([...value, u])
    }

    const onRemove = (u: UserOrUnionInvite) => {
        setValue(value.filter(v => !(v.id == u.id && v.__typename == u.__typename)))
    }

    const searchValueIsEmail = EmailValidator.validate(search);

    return <Space direction="vertical" style={{width: '100%'}}>
        <ListHolder>

            <Input allowClear variant={"outlined"} width={"100%"}
                   prefix={<IconSearch/>}
                   placeholder={t('membersList.search') as string} onChange={(e) => {
                setSearch(e.target.value?.toUpperCase())
            }}/>
            <ListHolder>

                {addEmails && users.length == 0 && searchValueIsEmail && <UserOrUnionListItem key={search} item={{
                    id: search,
                    __typename: "User",
                    fullName: search,
                    username: t('membersList.sendEmailInvite'),
                } as unknown as UserOrUnionInvite}
						                                                                                  onClick={onSelect}/>}

                {addEmails && users.length == 0 && !searchValueIsEmail && <div className={"not-found"}>
                    {t('membersList.searchNotFound')}
						    </div>}

                {users.map(u => <UserOrUnionListItem key={u.id} item={u} onClick={onSelect}/>)}
                {value.length > 0 && <div className={'selected-divider'}>{selectedText}</div>}
                {value.map(u => <UserOrUnionListItem key={u.id} item={u}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row-reverse"
                    }}>

                        <div style={{flexGrow: 0}}>
                            <IconClose onClick={_ => onRemove(u)}/>
                        </div>

                        {choiceTeamSlots && <div style={{flexGrow: 1}}>
                            {u.__typename == "UsersUnion" &&
	                            <ProjectUnionInvitesEditor
		                            onChange={(maxMembers) => {
                                    setValue(value.map(v => {
                                        if (v.id == u.id && v.__typename == u.__typename) {
                                            return {...v, maxMembers}
                                        }
                                        return v;
                                    }))
                                }}
	                            />
                            }
				                </div>}
                    </div>
                </UserOrUnionListItem>)}
            </ListHolder>
        </ListHolder>
    </Space>

};

export default UserSearch;