import React, {CSSProperties, useEffect, useRef, useState} from "react";
import {
    Event,
    EventDetail,
    EventDetailType,
    EventType,
    useAddTaskCommentLazyQuery,
    useEventsByTaskQuery,
    User
} from "../../generated-types";
import {Col, Grid, Row, theme} from "antd";
import {getI18n, useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Spinner from "../Spinner";
import UserAvatar from "../User/UserAvatar";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import {makeVar, useReactiveVar} from "@apollo/client";
import {authState} from "../../routes/Auth/authContext";
import AddCommentInput from "../AddCommentInput";
import TimeAgo from "../TimeAgo";
import styled, {css} from "styled-components";
import PointDivider from "../PointDivider";
import {IconCommentHistory} from "../Icon/IconCommentHistory";
import {IconComment} from "../Icon/IconComment";
import {IconHistory} from "../Icon/IconHistory";
import IconStateSwitcher, {IconState} from "./IconStateSwitcher";

interface TaskEventsProps {
    taskId?: string
}

dayjs.extend(duration)
dayjs.extend(relativeTime)

enum DisplayMode {
    all,
    messages,
    events
}

export type TaskAuthorAndDate={
    author?: User
    date?: any
}
export const taskAuthorAndDate=makeVar<TaskAuthorAndDate | undefined>(undefined)

// Для типизации обработчиков отрисовки конкретного типа деталей события
type EventTextFabricFunc<k> = (t: k, userCtx: string, d: EventDetail[]) => string;
// Тип для отрисовки события. Т.к. внутри события может быть много деталей с одинаковым типом, их нужно сгруппировать. Т.е.
// Этот тип нужен для группировки
type EventTextFabricArray = { [k in EventDetailType]: { items: EventDetail[], func: EventTextFabricFunc<k> } };
// словарик для обработчиков всех подтипов события.
type EventTextFabricType = { [k in EventDetailType]: EventTextFabricFunc<k> };

const getTranslatedText = (k: string | EventDetailType, userCtx: string, data: any) => {
    const {t} = getI18n();
    return t(`events.taskChanged.${k}`,{...data, actor: userCtx, interpolation: {escapeValue: false}});
}

//После подключения ICU i18n не кушает списки, поэтому джойним значения сразу
const eventTextFabric: EventTextFabricType = {
    addFile(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        // TODO: тут надо сделать ссылки на файлы, когда будет готово описание от дизайнеров
        return getTranslatedText(k, userCtx,  {files: d.map(v => v.payload?.caption).join(', ')});
    }, 
    addMember(k: EventDetailType,userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {members: d.map(v => v.payload?.caption).join(', ')});
    }, 
    addTag(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {tags: d.map(v => v.payload?.caption).join(', ')});
    }, 
    descriptionChange(k: EventDetailType,userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {value: d.map(v => v.payload?.value).join(', ')});
    }, 
    endDateChange(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {value: d.map(v => v.payload?.value).join(', ')});
    }, 
    endTimeChange(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {value: d.map(v => v.payload?.value).join(', ')});
    }, 
    fieldChange(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {value: d.map(v => v.payload?.value).join(', ')});
    }, 
    importanceChange(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {value: d.map(v => v.payload?.value).join(', ')});
    }, 
    removeFile(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {files: d.map(v => v.payload?.caption).join(', ')});
    }, 
    removeMember(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {members: d.map(v => v.payload?.caption).join(', ')});
    }, 
    removeTag(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {tags: d.map(v => v.payload?.caption).join(', ')});
    }, 
    resolvedChange(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {value: d.map(v => v.payload?.value).join(', ')});
    }, 
    startDateChange(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {value: d.map(v => v.payload?.value).join(', ')});
    }, 
    startTimeChange(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {value: d.map(v => v.payload?.value).join(', ')});
    }, 
    titleChange(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {value: d.map(v => v.payload?.value).join(', ')});
    }, 
    created(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, d);
    },
    taskTime(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {value: d.map(v => v.payload?.value).join(', ')});
    },
    taskEstimation(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return getTranslatedText(k, userCtx, {value: d.map(v => v.payload?.value).join(', ')});
    },
    hide(k: EventDetailType, userCtx: string, d: EventDetail[]): string {
        return '';
    }
} as EventTextFabricType

const EventMessageContainer = styled.div<{ $isMessage: boolean }>`
    position: relative;
    border-radius: 16px;
    padding: ${({$isMessage}) => $isMessage ? '12px' : 0};
    overflow-x: visible;
    margin-right: 6px;
    display: flex;
    flex-direction: column;
`

const MessageBubbleTailContaner=styled.div<{isCurrentUser: boolean, tailColor: string}>`
    position: absolute;
    transform: ${({isCurrentUser})=>isCurrentUser? 'translateX(50%)' : 'scaleX(-1) translateX(50%)'};
    color: ${({tailColor})=>tailColor};
    bottom: 0;
    height: 16px;
    ${({isCurrentUser})=>isCurrentUser? 'right: 0px' : 'left: 0px'};
    
    
`
const MessageBubbleTail=({isCurrentUser, tailColor}: {isCurrentUser: boolean, tailColor: string})=>{

    return <MessageBubbleTailContaner isCurrentUser={isCurrentUser} tailColor={tailColor}>
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8575 14.7826C6.53031 12.9764 5.021 3.41188 5.021 0.5C3.0907 2.49671 1.00531 9.79083 1.43051e-05 11.0388C2.5058 16.5503 16.0143 16.9351 10.8575 14.7826Z" fill="CurrentColor"/>
    </svg>
    </MessageBubbleTailContaner>

}

const EventDisplay = ({event}: { event: Event }) => {

    const {t} = getI18n();

    const authInfo = useReactiveVar(authState);

    const isCurUser = event.user.id == authInfo.user.id;
    const userContextString=isCurUser? 'you' : 'user'
    let isMessage=event.eventType == EventType.Message
    let result: any = "";
    const createByCtx=useReactiveVar(taskAuthorAndDate);

    if (isMessage) {
        result =<div>{event.eventDetails?.map(d => {
            const func = eventTextFabric[d.eventDetailType] as EventTextFabricFunc<typeof d.eventDetailType>;
            if (func)
                return func(d.eventDetailType, userContextString, [d]);
            return d.payload?.value ?? "NO MESSAGE";
        }).join()}</div>
    } else if (event.eventType == EventType.TaskChanged) {
        let rrr = event.eventDetails!
            .reduce((res: EventTextFabricArray, d: EventDetail) => {
                const k = d.eventDetailType;

                if(k===EventDetailType.Created && createByCtx && !createByCtx.author){
                    taskAuthorAndDate({author: event.user, date: event.logDate})
                }
                if (!res[k])
                    res[k] = {
                        items: [],
                        func: eventTextFabric[d.eventDetailType] as EventTextFabricFunc<typeof d.eventDetailType>
                    }
                res[k].items.push(d);
                return res;
            }, {} as EventTextFabricArray);

        result = Object.keys(rrr).map(k => (k as EventDetailType)).map((k: EventDetailType) => {
            const resText = rrr[k].func(k as never, userContextString, rrr[k].items)
            return <div key={k}>
                {t(`events.${userContextString}`)+' '}
                {!isCurUser && <span style={{fontWeight: 500}}>{event.user.fullName} </span>}
                {resText}
                </div>
        })
    } else {
        result = event.eventDetails?.map(d => {
            return d.payload?.value ?? "NO VALUE";
        })
    }

    const eventDeatilDisplayProps={isCurUser,isMessage,user: event.user, logDate: event.logDate}
    return <> {Array.isArray(result) ? result.map(res => <EventDeatailDisplay key={res}
        {...eventDeatilDisplayProps}
        eventContent={res}
        />
    ) : <EventDeatailDisplay 
    {...eventDeatilDisplayProps}
    eventContent={result}
    />}
    </>

}

function EventDeatailDisplay({isCurUser, isMessage=false, user, logDate, eventContent}:{isCurUser: boolean, isMessage?: boolean, user: User, logDate: any, eventContent: any}){

    const {token}=theme.useToken()
    const screen=Grid.useBreakpoint()
    const bubbleBackgroundColor=isMessage? (isCurUser? token.colors.ui.bgLight3 : token.colors.ui.bgLight) : 'unset'

    return <Row style={{
        flexDirection: isCurUser ? "row-reverse" : undefined, 
        margin: '16px 0px',
        }}>
        {!isCurUser && <Col flex={"40px"} style={{alignContent: isMessage ? "end" : "start"}}>
	        <UserAvatar size={"24"} user={user}/>
                        </Col>}
        <Col flex={"auto"} span={18} style={{display: "flex", flexDirection: isCurUser ? "row-reverse" : undefined}}>
            <EventMessageContainer $isMessage={isMessage} style={{
                backgroundColor: bubbleBackgroundColor,
                textAlign: (isCurUser && !isMessage) ? 'right' : undefined
            }}>
                {isMessage && !isCurUser && <div style={{fontWeight: "500"}}>{user.fullName}</div>}
                {eventContent}
                <span style={{textAlign: (isCurUser)? 'right' : undefined}}>
                <TimeAgo date={dayjs(logDate, {utc: false})}/>
                </span>
                {isMessage && <MessageBubbleTail isCurrentUser={isCurUser} tailColor={bubbleBackgroundColor}/>}
                </EventMessageContainer>
        </Col>
    </Row>
}

const ShadowBlockStyle=css`
        content: '';
        position: absolute;
        width: 100%;
        height: 30px;
        z-index: 1;

`
const LogShadowContainer = styled.div<{ $shadowColor: string }>`
    position: relative;
    flex-grow: 1;
    overflow-y: hidden;
    max-width: 100%;

    &::before{       
        top: 0;
        background: linear-gradient(to bottom, ${({$shadowColor}) => $shadowColor}, rgba(255, 255, 255, 0));
        ${ShadowBlockStyle}
    }

    &::after{
        bottom: 0;
        background: linear-gradient(to top, ${({$shadowColor}) => $shadowColor}, rgba(255, 255, 255, 0));
        ${ShadowBlockStyle}
    }
`

const AddCommentRow = styled(Row)<{ $shadowColor: string, $isMobile: boolean }>`

    position: relative;
    
    &::before{       
        top: 0;
        transform: translateY(-100%);
        background: linear-gradient(to top, ${({$shadowColor}) => $shadowColor}, rgba(255, 255, 255, 0));
        ${ShadowBlockStyle}
        opacity: 0.8;
        display: ${({$isMobile}) => $isMobile ? 'visible' : 'none'};
    }

    &::after{
        bottom: 0;
        transform: translateY(100%);
        background-color: ${({$shadowColor}) => $shadowColor};
        ${ShadowBlockStyle}
        display: ${({$isMobile}) => $isMobile ? 'visible' : 'none'};
    }

`

const TaskEvents = ({taskId}: TaskEventsProps) => {
    const {t} = useTranslation();
    const [mode, setMode] = useState(DisplayMode.all)
    const authInfo = useReactiveVar(authState);
    const {token}=theme.useToken()
    const logContainerRef= useRef<HTMLDivElement>(null);
    const screen=Grid.useBreakpoint()
    let {id} = useParams();
    if (!taskId)
        taskId = id;

    const {data, loading, refetch} = useEventsByTaskQuery({
        variables: {taskId: taskId!},
        //fetchPolicy: 'network-only',
        onCompleted: (_) => {
            scrollHistory();
            // logContainer?.scrollBy(1000, 5); // TODO: после отрисовки нужно сделать скролл вниз истории или вставить бесонечный скролл с частичным рендерингом
        }
    })


    const [addTaskComment] = useAddTaskCommentLazyQuery();

    let events = data?.EventsByTask;

    function scrollHistory(){
        if(logContainerRef.current)
            logContainerRef.current.scrollTop=logContainerRef.current.scrollHeight
    }

    useEffect(()=>{
        scrollHistory()
    }, [mode, events])

    if (loading)
        return <Spinner loading={loading}/>

    let selectedDisplayText = "";
    switch (mode) {
        case DisplayMode.all:
            selectedDisplayText = t('events.displayAll');
            break;
        case DisplayMode.messages:
            events = data?.EventsByTask.filter(v => v.eventType == "message")
            selectedDisplayText = t('events.displayMessages');
            break;
        case DisplayMode.events:
            events = data?.EventsByTask.filter(v => v.eventType != "message")
            selectedDisplayText = t('events.displayEvents');
            break;
    }

    const eventsCount = events?.length;

    const inputMobileStyle: CSSProperties={
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '0px 16px',
        zIndex: 1
    }

    return <div style={{padding: screen.xs? '16px 16px 80px 16px' : '16px 32px 0px 32px', display: 'flex', flexDirection: 'column',maxHeight: 'calc(100% - 16px)', height: 'calc(100% - 16px)', }}>
        <Row style={{alignItems: 'center'}}>
            <Col flex={"auto"}><span style={{opacity: 0.5}}>{selectedDisplayText}</span><PointDivider value={eventsCount}/></Col>
            <Col style={{alignSelf:'end'}}>
                <IconStateSwitcher
                    defaultValue={mode}
                    onChange={(v => {
                        setMode(v)
                    })}
                    title={t('events.view')}
                    items={[
                        {
                            state: DisplayMode.messages,
                            icon: <IconComment strokeWidth={1.5}/>,
                            tooltipText: t('events.displayMessagesTooltip')
                        },
                        {
                            state: DisplayMode.all,
                            icon: <IconCommentHistory/>,
                            tooltipText: t('events.displayAllTooltip')
                        },
                        {
                            state: DisplayMode.events,
                            icon: <IconHistory/>,
                            tooltipText: t('events.displayEventsTooltip')
                        },
                    ] as IconState<DisplayMode>[]
                    }
                />
            </Col>
        </Row>
        <LogShadowContainer $shadowColor={token.colors.ui.bgLight2}>
                <Row ref={logContainerRef} style={{height: '100%', overflowY: 'auto'}}>
                    <Col span={24} style={{padding: 0, alignSelf: 'end'}}>
                        {events?.map(e => <EventDisplay key={e.id} event={e as Event}/>)}
                    </Col>
                </Row>
            </LogShadowContainer>
        {mode!==DisplayMode.events && <AddCommentRow style={{marginBottom: screen.xs? 16 : 32, ...(screen.xs && inputMobileStyle) }}
                                                     $shadowColor={token.colors.ui.white}
                                                     $isMobile={screen.xs!}
        ><Col span={24}>
            <AddCommentInput onRun={(text: string, callback) => {
                addTaskComment({
                    variables: {
                        input: {
                            taskId: taskId,
                            text: text,
                        }
                    },
                    onCompleted: (data) => {
                        refetch()
                        callback();
                    }
                })
            }}/>
        </Col></AddCommentRow>}
    </div>
}

export default TaskEvents