import {allUsersUnions} from "../subscriptions/allUsersUnions";
import {allProjects} from "../subscriptions/allProjects";
import { Breadcrumb, ConfigProvider } from "antd";
import { Link } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import styled from "styled-components";

const BreadcrumbStyled=styled(Breadcrumb)`
    opacity: 1;
    line-height: 1;
    
    .ant-breadcrumb-link{
        
        opacity: 1;
        font-size: 12px;
        line-height: 1;

        >a{
            color: ${({theme})=>theme.colors.font.primary};
            padding: 0;
            margin: 0;
            text-decoration: none;
            line-height: 1;
            opacity: 1;
            height: auto;
            background-color: unset;
            transition: all .3s ease-in-out;
            font-weight: 400;

            &:hover{
                opacity: .5;
            }
        }

    }

    .ant-breadcrumb-separator{
        color: ${({theme})=>theme.colors.font.primary};
        opacity: .3;
    }
`

const ProjectBreadcrumb: React.FC<{projectId: string}> = ({projectId}) => {

    useReactiveVar(allUsersUnions);
    const allP = useReactiveVar(allProjects);

    const project = allP.projects.find(p=>p.id == projectId)

    let description = [];
    if (project) {
        if (project.usersUnionAsOwner)
            description.push({
                title: <Link onClick={(e)=>e.stopPropagation()} to={`/usersUnion/${project.usersUnionAsOwner.id}`}>{project.usersUnionAsOwner.title}</Link>
            });
        if (project.userAsOwner)
            // TODO: если как участник - нужно сделать тут отображение
            description.push({
                title: <Link onClick={(e)=>e.stopPropagation()} to={`/user/${project.userAsOwner.id}`}>{project.userAsOwner.username}</Link>
            });

        description.push({
            title: <Link onClick={(e)=>e.stopPropagation()} to={`/project/${project.id}`}>{project.title}</Link>
        });
    }
    return (<BreadcrumbStyled
    items={description} 
    separator={<span>•</span>}
    />
    )
}

export default ProjectBreadcrumb