import {Button, Tag, theme} from "antd";
import {useTaskChangeImportanceMutation} from "../../generated-types";
import {CSSProperties, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {IconArrowDown} from "../Icon/IconArrowDown";
import SelectMenuDropdown, {CustomDropdownItem} from "../AntCastomComponents/SelectMenuDropdown";
import {IconPriorityHigh} from "../Icon/IconPriorityHigh";
import Dropdowned from "../Dropdowned";
import {GlobalToken} from "antd/es/theme/interface";
import styled from "styled-components";


export interface TaskImportanceProps {
    // а если не указан идентификатор, тогда надо предавать значение выше и чтобы код выше его сохранял (например, в карточке новой задачи)
    id: string
    value: number,
    onChange?: (value: number) => void;
    sendOnChange?: boolean //отправлять запрос при изменении
}

type Priority = {
    title: string,
    bgColor: string,
    hoverColor: string,
    border?: string
    borderHover?: string
    icon?: React.JSX.Element
}

const PriorityName = ({ priority, textStyle }: { priority: Priority, textStyle?: CSSProperties }) => {

    const { t } = useTranslation();

    return <div style={{display: "flex", alignItems: 'center', gap: 4, height: 16}}>
        {priority.icon && <span style={{ fontSize: 16, lineHeight: 1 }}>{priority.icon}</span>}
        <span style={{
            fontSize: 12,
            fontWeight: 300,
            lineHeight: 1,
            ...textStyle
        }}>{t("task.priority." + priority.title)}</span>
    </div>
}

const TagStyled = styled(Tag)<{}>`
    padding: 4px 8px;
    border-radius: 8px;
    margin: 0;
    box-sizing: border-box;
`


const ButtonStyled = styled(Button)<{}>`
    width: 165px;
    box-shadow: unset;
    color: ${p => p.theme.colors.font.primary} !important;
    flex-direction: row-reverse;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    height: auto;
`

export const TaskImportanceElement = ({priority}: { priority: Priority }) => {
    return <TagStyled className={'task-importance-item'}
                      style={{backgroundColor: priority.bgColor, border: priority.border ? priority.border : '0px',}}
    >
        <PriorityName priority={priority} />
    </TagStyled>
}

export const allPriors = {
    withoutPriority: 'withoutPriority',
    low: 'low',
    medium: 'medium',
    critical: 'critical',
};

export function allPriorities(token: GlobalToken): Priority[] {
    return [
        {
            title: allPriors.withoutPriority,
            bgColor: 'unset',
            hoverColor: 'unset',
            border: `solid 2px ${token.colors.ui.bgLight}`,
            borderHover: `solid 2px ${token.colors.ui.bgLight}`
        },
        {
            title: allPriors.low,
            bgColor: token.colors.ui.bgLight3,
            hoverColor: token.colors.ui.bgLight
        },
        {
            title: allPriors.medium,
            bgColor: token.colors.ui.priorityMedium,
            hoverColor: token.colors.ui.priorityMediumHover
        },
        {
            title: allPriors.critical,
            bgColor: token.colors.ui.priorityHigh,
            hoverColor: token.colors.ui.priorityHighHover,
            icon: <IconPriorityHigh/>
        }
    ]
}

export const taskPriorityByName=(name: string): number=>{
    switch (name) {
            case allPriors.low:
                return 1;
            case allPriors.medium:
                return 2;
            case allPriors.critical:
                return 3;
            default:
                return 0;
        }
}
function TaskImportance({id, value, onChange, sendOnChange=true}: TaskImportanceProps) {
    const {t} = useTranslation();
    const [elementValue, setValue] = useState<number>(value)
    const [hover, setHover] = useState(false)
    const {token} = theme.useToken()
    const [opened, setOpened] = useState(false);
    const btnRef = useRef<HTMLButtonElement>(null)

    const [taskChangeImportance] = useTaskChangeImportanceMutation({
        onCompleted: () => {

        }
    })

    const priority = useMemo(() => allPriorities(token), [token]);

    const dropdownItems: CustomDropdownItem[] = priority.map((priority, index) => ({
        label: <TaskImportanceElement priority={priority} />,
        type: "item",
        key: index,
        selected: index === elementValue,
        onClick: async () => {
            setOpened(false)
            if(sendOnChange)
                await taskChangeImportance({
                    variables: {
                        importance: index,
                        id: id
                    },
                    onCompleted: () => {
                        setValue(index)
                    }

                })
            else
                setValue(index)
            if (onChange)
                onChange(index)
        }
    } as CustomDropdownItem)).reverse()

    const backgroundColor = hover ? priority[elementValue]?.hoverColor : priority[elementValue]?.bgColor

    return (<>
            <ButtonStyled style={{
                    border: priority[elementValue]?.border ? priority[elementValue]?.border : '0px',
                    backgroundColor: backgroundColor,
                }}
                    styles={{ icon: { display: "flex", alignItems: 'center' } }}
                    icon={<IconArrowDown strokeWidth={'1.5px'} />}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={()=>setOpened(true)}
                    ref={btnRef}
                >
                    <PriorityName priority={priority[elementValue] || priority[0]}/>
            </ButtonStyled>
                {opened && <Dropdowned opened={opened}
                title={t('task.priority.prioritySelectTitle')} 
                onClose={()=>setOpened(false)}
                anchor={btnRef}
                >
                            <SelectMenuDropdown items={dropdownItems}/>
                    </Dropdowned>}
                </>
)
}

export default TaskImportance