import React, {useEffect, useState} from "react";
import {
    Project,
    ProjectChangePriorityAndStatusMutation,
    ProjectMemberInvite,
    useProjectChangePriorityAndStatusMutation,
    UsersUnion
} from "../../generated-types";
import client from "../../ApolloClient";
import {GeneralBoardItemType} from "../GeneralBoard/GeneralBoardCard";
import {t} from "i18next";
import {GeneralBoardColumnType} from "../GeneralBoard/GeneralBoardColumn";
import ProjectsListCard from "../Project/ProjectsListCard";
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";
import TooltipButton from "../TooltipButton";
import {UserSwitchOutlined} from "@ant-design/icons";
import ProcessProjectInvite from "./ProcessProjectInvite";
import GeneralBoard from "../GeneralBoard/GeneralBoard";

export enum ProjectStatuses {
    new = "new",
    frozen = "frozen",
    active = "active",
    finished = "finished"
}


export const statuses = [ProjectStatuses.new, ProjectStatuses.frozen, ProjectStatuses.active, ProjectStatuses.finished];

const UsersUnionProjectsBoard: React.FC<{
    union: UsersUnion;
    projects: Project[];
    invites: ProjectMemberInvite[];
    onChange?: (checked: boolean) => void;
}
> = ({union, projects, invites}) => {
    const [drop, setDrop] = useState<[Project | undefined, Project | undefined, string | undefined]>([undefined, undefined, undefined])
    const [inviteInfo, setInviteInfo] = useState<ProjectMemberInvite | null>(null);
    const [moveProject] = useProjectChangePriorityAndStatusMutation({
        optimisticResponse: ({projectId, status, priority}) => {
            const res = {
                projectChangePriorityAndStatus: {
                    id: projectId,
                    priority,
                    status
                }
            } as ProjectChangePriorityAndStatusMutation;
            return res;
        }
    })

    const byPriority = (p1: Project | GeneralBoardItemType<Project>, p2: Project | GeneralBoardItemType<Project>) => {

        const project1 = 'item' in p1 ? p1.item : p1
        const project2 = 'item' in p2 ? p2.item : p2

        if (project1.priority != project2.priority) return project1.priority < project2.priority ? 1 : -1
        return project1.id < project2.id ? 1 : -1
    };

    useEffect(() => {

        const [droppedProject, droppedOnProject, col] = drop
        if (!droppedProject) return

        const column = (droppedOnProject ? droppedOnProject.status : col) as string

        const columnProjects = projects.filter(value => value.status === column)

        let underPosition = droppedOnProject?.priority ?? 0
        const ps = columnProjects.filter(v => v.priority >= underPosition && v.id != droppedOnProject?.id).sort(byPriority);
        const upperProject = ps.pop();

        if (!underPosition && !upperProject?.priority) underPosition = 1000000;

        const upperPosition = upperProject?.priority ?? underPosition * 3
        const priority: number = underPosition + (upperPosition - underPosition) / 2;

        client.cache.modify({
            fields: {
                priority() {
                    return priority
                },
                status() {
                    return column
                }
            },
            id: client.cache.identify(droppedProject)
        })


        moveProject({
            variables: {
                projectId: droppedProject.id,
                usersUnionId: union.id,
                priority,
                status: column
            },
        })


    }, [drop])

    const boardProjects = projects.map((value) => ({
        id: value.id,
        columnId: value.status,
        item: value
    } as GeneralBoardItemType<Project>))

    const boardColumns = statuses.map((status) => ({
        id: status,
        title: t(`project.status.${status}`),
        item: status
    }) as GeneralBoardColumnType<string>)

    const projectsBoardColumnSplitter = (column: GeneralBoardColumnType<string>, items: GeneralBoardItemType<Project>[]) => {
        return items.filter(item => item.columnId === column.id).sort(byPriority)
    }

    const onProjectRender = (project: Project) => {
        const ou = project.userAsOwner ?? project.usersUnionAsOwner;
        const ownerTitle = project.userAsOwner?.fullName
            ?? project.userAsOwner?.username ?? project.usersUnionAsOwner?.title;
        const i = invites.find(v => v.project.id == project.id) ?? null;

        return <ProjectsListCard key={project.id + '-' + project.status + '-' + project.priority} project={project}
                                 showStatus={false} showButtons={true}
                                 ownerInfo={ou && <div style={{display: "flex"}}>
					                         <Breadcrumb style={{flex: 1}} items={[{
                                       title: <Link to={`/${ou?.__typename}/${ou?.id}`}>{ownerTitle}</Link>
                                   }]} rootClassName={"projectBreadcrumbLink"}/>
					                         <TooltipButton
						                         tooltipProps={{
                                         title: t("usersUnion.projectInvitesManage")
                                     }}
						                         type={"link"} onClick={(event) => {
                                       event.stopPropagation();
                                       setInviteInfo(i);
                                   }}
					                         ><UserSwitchOutlined/></TooltipButton>
				                         </div>}
                                 showFavorite={true}></ProjectsListCard>
    }

    const onProjectDropped = (droppedProject: Project, droppedOnProject?: Project, column?: string) => {
        setDrop([droppedProject, droppedOnProject, column])
    }

    return <div style={{height: "calc(100vh - 150px)"}}>
        {inviteInfo && <ProcessProjectInvite key={inviteInfo?.id} invite={inviteInfo} union={union}
				                                     onClose={() => setInviteInfo(null)}/>}
        <GeneralBoard
            signature='PROJECT'
            items={boardProjects}
            columns={boardColumns}
            columnsSplitter={projectsBoardColumnSplitter}
            onItemRender={onProjectRender}
            onItemDropped={onProjectDropped}
        />
    </div>
}

export default UsersUnionProjectsBoard;