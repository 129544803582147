import React, {useState} from "react";
import {Button, Input, Spin, theme} from "antd";
import {useTranslation} from "react-i18next";
import {IconSend} from "./Icon/IconSend";
import styled from "styled-components";

interface AddCommentInputProps {
    placeholder?: string,
    onCompleted?: (event: Event) => void,
    onRun: (text: string, callback: () => void) => void,
    isBlocked?: boolean | undefined
}

const AddCommentInputContainer = styled.div<{ $backgroundColor: string }>`
    align-items: end;
    display: flex;
    gap: 8px;
    border-radius: 8px;
    background-color: ${({$backgroundColor}) => $backgroundColor};
    padding-right: 8px;
    transition: all .3s
`
//TODO: сделать чтобы поле подстаивалось под текст
const AddCommentInput = ({onRun, placeholder, onCompleted, isBlocked = false}: AddCommentInputProps) => {
    const {t} = useTranslation();
    const [focused, setFocused] = useState(false);
    const [hover, setHover]=useState(false);
    const {token}=theme.useToken()
    const [inProgress, setInProgress] = useState(false);
    const [text, setText] = useState("");

    const AddMessage = (fastAdd: boolean) => {
        if (inProgress)
            return

        if (!text || !text.trim())
            return

        if (fastAdd) {
            setInProgress(true)
            onRun(text, () => {
                setFocused(false);
                setInProgress(false)
                setText("");
            })
        } else {
            setText("")
        }
    }

    const containerBg=(hover || focused)? token.colors.ui.bgLight3 : token.colors.ui.bgLight
    return <div>
        <Spin spinning={inProgress}>
            <AddCommentInputContainer $backgroundColor={containerBg}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
            <Input.TextArea disabled={isBlocked} style={{
                padding: '10.5px 8px 10.5px 16px',
                backgroundColor: 'unset',
                width: '100%', 
                height: 40, 
                fontSize: 12}}
                   placeholder={placeholder ?? t('events.addComment')}
                   variant={"outlined"}
                   onChange={(e) => {
                       if (inProgress) {
                           e.preventDefault()
                           e.stopPropagation()
                           return;
                       }
                       setText(e.target.value as any)
                   }}
                   onKeyPress={(e) => {
                       if (e.charCode !== 13)
                           return;
                       AddMessage(!e.ctrlKey)
                   }}
                   size="middle"
                   autoSize={{minRows: 1, maxRows: 12}}
                   /*suf*/
                   onFocus={() => setFocused(true)}
                   onBlur={() => setFocused(false)}
                   value={text}
                   //styles={{suffix: {width: 24},}}
            />
            {(focused || text) && <Button type={"text"} style={{
                    width: 24, 
                    height: 24, 
                    backgroundColor: token.colors.ui.accent,
                    color: token.colors.font.white,
                    lineHeight: 1,
                    margin: '8px 0px'
                    }} 
                    styles={{icon: {height: 16}}}
                onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        AddMessage(!e.ctrlKey)

                   }}
                   icon={<IconSend style={{alignSelf: 'center', fontSize: 16}}/>}
                   />}
            </AddCommentInputContainer>
        </Spin>
    </div>
}

export default AddCommentInput