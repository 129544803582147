import React, {forwardRef, ReactElement, useRef, useState} from "react";
import {theme} from "antd";
import {Tag} from "../../generated-types";
import TagDisplayItem from "./TagDisplayItem";
import Dropdowned from "../Dropdowned";
import styled from "styled-components";
import {GlobalToken} from "antd/es/theme/interface";


interface TagsDisplayProps {
    tags: Tag[]
    /**
     * По-умолчанию нет ограничений в количестве линий
     */
    maxLines?: number
    maxTags?: number
    allowRemove?: boolean
    onRemove?: (tag: Tag) => void,
    addButton?: ReactElement
}

const TagsDisplayStyled = styled.div<{ $token: GlobalToken }>`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    
    .overflow {
        color: ${p => p.$token.colors.font.primary};
        border-radius: 20px;
        border: 1px solid ${p => p.$token.colors.ui.bgLight};
        display: flex;
        font-size: 10px;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        font-weight: 200;
        cursor: pointer;
    }

    .add-tags-button > .ant-btn-icon {
        align-content: center;
        
        svg {
            width: 24px;
            height: 24px;
        }
    }

    .dropdowned > .content-container {
        padding: 8px
    }
`;


const TagsDisplay = forwardRef<HTMLDivElement, TagsDisplayProps>(({
                                                     tags,
                                                     maxTags,
                                                     maxLines,
                                                     onRemove,
                                                     allowRemove = true,
                                                     addButton
                                                 }, ref) => {
    const {token} = theme.useToken();
    const anchorRef = useRef<HTMLDivElement>(null)
    const [showAdditional, setShowAdditional] = useState(false)

    let showTags = tags;
    let additionalTags: Tag[] = [];
    if (maxTags) {
        showTags = tags.filter((v, i) => i < maxTags);
        additionalTags = tags.filter((v, i) => i >= maxTags);
    }

    let delta = tags.length - showTags.length;
    
    return <TagsDisplayStyled $token={token} ref={ref}>
        {showTags.map((tag) => (
            <TagDisplayItem key={tag.id + "_" + tag.title} tag={tag} removable={allowRemove} onRemove={() => {
                if (onRemove) onRemove(tag);
            }}/>))}
        {delta > 0 && <div className={"overflow"} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowAdditional(true)
        }} ref={anchorRef}>+{delta}</div>}
        {showAdditional &&
	        <Dropdowned opened={showAdditional} title={""} onClose={() => setShowAdditional(false)} anchor={anchorRef}
            popupOnly
	        ><TagsDisplayStyled $token={token}>
              {additionalTags.map((tag) => (
                  <TagDisplayItem key={tag.id + "_" + tag.title} tag={tag} removable={false} onRemove={() => {
                      if (onRemove) onRemove(tag);
                  }}/>))}
                  </TagsDisplayStyled>
					</Dropdowned>}
        {addButton}
    </TagsDisplayStyled>
})

export default TagsDisplay;