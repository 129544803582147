/**
 * TODO: техдолг
 * Это временный класс, в который собираются все запросы, которые тупо обновляются через refetchQueries.
 * Потом это надо переделать на нормальную инвалидацию или модификацию кеша. а не всё заливать с сервера
 * Этот механизм надо хорошо продумывать. А пока нет возможностей
 */
export default class RefetchesList {
    public static TasksByViewId: string = "tasksByViewId"
    public static YandexDiskGetOAuthLink: string = "yandexDiskGetOAuthLink"
    public static Project: string = "project"
    public static UsersUnion: string = "usersUnion"
    // public static ProjectsListDocument = null;//ProjectsListDocument
    public static ProjectDocument: string = "projectDocument"
    public static Task: string = "task"
    public static ProjectCard= "projectCard"
    public static YandexDiskGetFolderItems="yandexDiskGetFolderItems"
    public static TasksCount="tasksCount"
}